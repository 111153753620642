// Seguros

.wrapper-insurance {
  padding: 1rem;

  .title {
    color: $tc-black;
    font-size: 1.875rem;
    text-align: center;
  }

  .box-insurance {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;

      li {
        display: inline-block;
        border-radius: 8px;
        padding: 1rem;
        margin: 0 0.8rem 0.5rem 0;
        width: 95%;
        box-shadow: $shadow-default;

        h3 {
          color: $tc-black;
          font-weight: 500;
          font-size: 1rem;
        }

        p {
          font-weight: 300;
          color: $grey-text;
          font-size: 0.75rem;
          padding-bottom: 0.5rem;
          border-bottom: 1px solid rgba(164, 164, 164, 0.3);
        }

        .btn-active {
          border: none;
          border-radius: 30px;
          width: 60%;
          padding: 0.3rem 0;
        }
      }
    }
  }
  // Lista de Seguros
  .list-insurance {
    .title {
      font-weight: 500;
      color: $tc-black;
      font-size: 2rem;
      margin: 1rem 0 0.5rem 0;
      text-align: center;
    }

    .subtitle {
      color: $tc-black;
      font-weight: 300;
      font-size: 1.25rem;
      margin: 0;
      text-align: center;
    }

    .list {
      padding: 1rem 0 0 0;
      margin: 0;
      list-style: none;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px 10px;

      li {
        position: relative;
        min-height: 160px;
        overflow: hidden;
        border-radius: 3px;

        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        cursor: pointer;

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.3);
          z-index: 2;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          right: 0;
          bottom: 0;
          height: 105%;
          width: 105%;
          transform: translate(-50%, -50%);
        }

        p {
          position: relative;
          z-index: 3;
          color: $tc-white;
          font-size: 1.563rem;
          margin: 0;
          text-align: center;
        }
      }

      .text-insurance {
        grid-column: 1 / span 2;
        margin: 0 0 0.5rem 0;
        font-size: 1rem;
        // text-align: center;
      }
    }
  }

  .box-img {
    position: relative;
    overflow: hidden;
    min-height: 300px;
    border-radius: 5px;

    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 2;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // transform: translate(0, -45%);
      height: 100%;
      width: 100%;
    }

    .box-info {
      position: relative;
      z-index: 3;

      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);

      width: 80%;

      .info {
        h3 {
          color: $tc-white;
          font-weight: 500;
          font-size: 2.813rem;
          margin-bottom: 0;
        }

        p {
          color: $tc-white;
          font-weight: 300;
          font-size: 1.25rem;
          margin: 0;
          max-width: 75%;
        }
      }

      .btn-action {
        width: 25%;

        .btn-active {
          width: 100%;
        }
      }
    }
  }

  .insurance-alert {
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }

  .insurance-info {
    width: 70%;
    margin: 0 auto;
    padding: 1rem;

    .p-relative {
      position: relative;

      & > ul {
        padding: 0;
        margin: 0;
        list-style: none;

        @include flexbox();

        li {
          width: 100%;
          text-align: center;
          cursor: pointer;
          padding: 0.5rem;
          border-bottom: 2px solid rgba(164, 164, 164, 0.3);

          p {
            font-weight: 500;
            font-size: 1.25rem;
            color: $grey-text;
            opacity: 0.5;
            margin: 0;
          }

          &:hover {
            border-bottom: 2px solid $tc-red;

            p {
              color: $tc-red;
              opacity: 1;
            }
          }
        }

        .active {
          border-bottom: 2px solid $tc-red;

          p {
            color: $tc-red;
            opacity: 1;
          }
        }
      }

      .arrow {
        position: absolute;
        right: 0;
        top: 0;
        width: 60px;
        height: 100%;
        font-size: 18px;
        color: #555;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, #fff 40%, #fff);
        filter: progid:dximagetransform.microsoft.gradient(startColorstr="#00ffffff", endColorstr="#ffffff", GradientType=1);
        z-index: 3;
        pointer-events: none;
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;

        span {
          img {
            width: 20px;
          }
        }
      }
    }

    .text {
      margin: 1rem 0;

      h3 {
        color: $tc-black;
        font-weight: 300;
        font-size: 1.125rem;
      }

      p {
        color: $grey-text;
        font-weight: 300;
        font-size: 0.875rem;
      }
    }

    #accordion {
      .card-header {
        background: transparent;

        h5 {
          font-weight: 300;
          color: $tc-black;
          font-size: 1.125rem;

          @include flexbox();
          @include justify-content(space-between);
          @include align-items(center);
        }

        .btn-link {
          appearance: none;
          background-image: linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
          background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
          background-size: 5px 5px, 5px 5px, 1px 1.5em;
          background-repeat: no-repeat;
          font-size: 0 !important;
          color: $grey-text !important;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      .card-body {
        color: $grey-text;
        font-weight: 300;
        font-size: 0.875rem;
      }
    }
  }

  @media (max-width: 992px) {
    .box-img {
      img {
        transform: inherit;
      }

      .box-info {
        @include flex-direction(column);
        @include align-items(end);

        width: 100%;
        padding: 1.5rem;

        .info {
          margin-bottom: 1rem;
        }
      }
    }

    .insurance-info {
      width: 100%;
    }

    .box-insurance {
      ul {
        grid-template-columns: 50% 50%;
      }
    }
  }

  @media (max-width: 576px) {
    .insurance-info {
      .p-relative {
        ul {
          flex-wrap: nowrap;
          overflow: scroll;

          li {
            margin: 0.5rem 0;
            flex: 0 0 185px;
            text-align: center;
          }
        }

        .arrow {
          display: flex;
        }
      }
    }

    .list-insurance {
      .title {
        font-size: 1.5rem;
      }

      .list {
        grid-template-columns: 1fr;

        .text-insurance {
          grid-column: inherit;
          text-align: center;
        }
      }
    }

    .box-img {
      min-height: 150px;

      .box-info {
        .info {
          width: 100%;
          text-align: center;

          h3 {
            font-size: 2rem;
          }

          p {
            max-width: 100%;
            font-size: 1rem;
          }
        }

        .btn-action {
          width: 100%;
        }
      }
    }

    .box-insurance {
      ul {
        grid-template-columns: 1fr;

        li {
          width: 100%;
          margin: 0.5rem 0;

          .btn-active {
            width: 100%;
          }
        }
      }
    }
  }
}

.n-p {
  padding: 0;
}

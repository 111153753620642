// Contenido
.content {
  .title {
    text-align: center;
    padding: 2rem 0;
  }

  .withdrawal {
    border: 1px solid $tc-grey;
    border-radius: 5px;
    box-shadow: $shadow-default;

    .stepper {
      margin: 1rem 0;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        li {
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);

          &::after {
            content: '';
            width: 3vw;
            border-top: 1px solid silver;
          }

          &:last-child {
            &::after {
              content: none;
            }
          }

          span {
            border-radius: 40px;
            width: 40px;
            height: 40px;
            margin: 0 0.5rem;
            color: $tc-white;
            background: rgba(0, 0, 0, 0.3);

            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
          }
        }

        .active {
          span {
            background: $tc-red;
            color: $tc-white;
          }
        }
      }
    }

    .wrapper-four {
      display: grid;
      grid-template-columns: 100%;
      padding: 1rem;

      .one {
        .content-text {
          margin: 0.8rem 0;

          p {
            font-size: 1.313rem;
            color: $tc-black;
            text-align: center;
            margin: 0 auto;
            max-width: 70%;

            span {
              margin-right: 0.5rem;
              color: $tc-red;
            }
          }
        }

        .form-check{
          width: 70%;
          margin: 0 auto;
          text-align: center;
          .btn{
            background: transparent;
            border: none;
            color: $tc-black;
            padding: 0;
            text-decoration: underline;
            &:focus{
              outline: none;
              box-shadow: none;
            }
          }
          .modal{
            .modal-header{
              border: none;
              display: block;
              .close{
                background: rgba(0, 0, 0, 0.8);
                color: #fff;
                border-radius: 50%;
                width: 10px;
                height: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 20px;
                right: 20px;
                position: absolute;
                font-weight: 100;
                cursor: pointer;
                z-index: 4;
              }
            }
            .modal-body{
              text-align: left;
            }
          }
        }
        .btn-action {
          width: 35%;
          margin: 2rem auto;

          .btn-active {
            width: 100%;
            color: $tc-white !important;
          }
        }
      }

      @media (max-width: 992px) {
        grid-template-columns: 1fr;

        .one {
          border: none;

          .content-text {
            p {
              max-width: 100%;
            }
          }
        }
      }

      @media (max-width: 768px) {
        .one {
          .btn-action {
            width: 100%;

            .btn-active {
              width: 100%;
            }
          }
        }
      }

      @media (max-width: 576px) {
        .one {
          padding: 0;

          .content-text {
            p {
              font-size: 1rem;

              span {
                font-size: 1rem;
              }
            }
          }

          .btn-action {
            width: 100%;

            .btn-active {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

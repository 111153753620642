.wrapper {
  h4 {
    font-size: 1.563rem;
    color: $tc-black;
    text-align: center;
    font-weight: 100;
  }

  .title-pass {
    padding: 1rem 0;
    margin: 0;
  }

  .text-number {
    // max-width: 30%;
    margin: 0 auto;
    text-align: center;
    color: $grey-text;
  }

  .text-number-error {
    // max-width: 30%;
    margin: 0 auto;
    text-align: center;
    color: $tc-red;
  }

  .container-input {
    margin: 1.25rem 0;

    .info-text {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);

      margin: 1.25rem 0;

      .email {
        width: 25px;
        margin-right: 10px;
      }

      .text-email {
        margin: 0;
        font-weight: 500;
        color: $tc-black;
      }
    }

    .email-code {
      max-width: 30%;
      margin: 0 auto;

      .form-pincode {
        text-align: center;

        .code-input {
          background: #fcfcfc;
          border: 1px solid #e3e3e3;
          padding: 1rem;
        }

        label {
          display: block;
          text-align: left;
          font-weight: 500;
          color: $tc-black;
        }

        input {
          display: inline-block;
          width: 14%;
          height: 50px;
          line-height: 48px;
          text-align: center;
          font-size: 2em;
          color: #181819;
          border: 0;
          border-bottom: 2px solid rgba(0, 0, 0, 0.3);
          border-radius: 2px 2px 0 0;
          transition: background-color 0.3s, color 0.3s, opacity 0.3s;
          cursor: default;
          user-select: none;
          margin: 0;
          margin-top: 10px;
          margin-right: 2%;
          padding: 0;

          &:focus {
            outline: 0;
            box-shadow: none;
            border-color: $tc-red;
            animation: border-pulsate 1.5s infinite;
            -webkit-tap-highlight-color: transparent;
          }
        }
      }
    }
  }

  table {
    tr {
      display: grid;
      grid-template-columns: 30% 30% 30%;
      grid-gap: 15px;
      justify-content: center;

      td {
        label {
          font-size: 0.75rem;
          font-weight: 500;
        }
      }
    }
  }

  .btn-action {
    text-align: center;
    margin: 1rem 0;
  }

  .wrapper-pass {
    display: grid;
    grid-template-columns: 50% 1px 50%;
    padding: 1.5rem 0;

    .one,
    .two {
      margin: 0.5rem;
      padding: 0 1rem;
    }

    .one {
      h3 {
        font-size: 1.563rem;
        font-weight: 400;
        color: $tc-black;
      }

      .text {
        color: $grey-text;
        font-size: 0.875rem;

        span {
          color: $tc-black;
          font-weight: bold;
        }
      }
    }

    .two {
      .form-group {
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items(center);
        @include justify-content(space-between);

        margin-bottom: 0.938rem;

        label {
          font-size: 0.875rem;
        }

        .form-control {
          width: 50%;
        }

        .invalid-feedback {
          text-align: right;
        }
      }

      .btn-action {
        padding: 1.5rem 0;

        .btn-active {
          width: 50%;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .text-number {
      max-width: 60%;
    }

    .container-input {
      .email-code {
        max-width: 60%;
      }
    }

    table {
      tr {
        grid-template-columns: 1fr;
        grid-gap: 0;

        td {
          .form-group {
            margin-bottom: 0;
          }
        }
      }
    }

    .btn-action {
      .btn-active {
        width: 50%;
      }
    }

    .wrapper-pass {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 796px) {
    .wrapper-pass {
      .two {
        .form-group {
          @include flex-direction(column);

          align-items: inherit;

          .form-control {
            width: 100%;
          }
        }

        .btn-action {
          .btn-active {
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .text-number {
      max-width: 100%;
    }

    .container-input {
      .email-code {
        max-width: 100%;
      }
    }

    h4 {
      font-size: 1.3rem;
    }

    .btn-action {
      .btn-active {
        width: 100%;
      }
    }

    .wrapper-pass {

      .one,
      .two {
        padding: 0;
      }
    }
  }
}
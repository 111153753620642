// Mi cuenta
.account {
  // Seccion 1
  .wrapper-one {
    display: grid;
    grid-template-columns: 50% 1px 50%;
    box-shadow: $shadow-default;

    .one,
    .two {
      margin: 1.5rem;

      h2 {
        margin: 0;
        font-weight: 500;
        color: $tc-black;
        font-size: 1.25rem;
      }

      .list-data {
        padding: 0;
        margin: 1rem 0;
        list-style: none;

        li {
          @include flexbox();
          @include align-items(center);
          @include justify-content(space-between);

          margin: 0.5rem 0;

          p {
            margin: 0;
            font-weight: 300;
            color: $grey-text;
            font-size: 0.875rem;
          }
        }

        .three-items {
          span {
            margin: 0;
            font-weight: 300;
            color: $grey-text;
            font-size: 0.625rem;
            display: block;
          }
        }

        .box-shadow {
          padding: 0.5rem;

          .more-info {
            max-width: 300px;

            p {
              color: $tc-black;
              font-size: 0.85rem;

              &:last-child {
                color: $grey-text;
                font-size: 0.725rem;
              }
            }
          }

          .custom-switch {
            label {
              font-size: 0;
            }

            .custom-control-input {
              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
          }
        }
      }
    }

    .divider {
      height: 90%;
      margin: auto;
    }

    .one {
      .box-card {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);

        .credit-card {
          @include flexbox();
          @include align-items(center);

          .btn-lock {
            background: transparent;
            border: none;
            padding: 0;
            margin: 0 0 0 0.5rem;

            img {
              width: 14px;
              vertical-align: baseline;
            }
          }
        }

        .form-group {
          margin: 0;
          width: 50%;
        }
      }

      .bar-progress {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);

        margin-top: 0.8rem;

        .values {
          text-align: center;

          .active {
            color: $tc-red;
          }

          p {
            margin: 0;
            font-weight: 300;
            color: $grey-text;
            font-size: 0.75rem;
          }

          span {
            font-weight: 500;
            color: $tc-black;
            font-size: 1.25rem;
          }
        }
      }

      .progress {
        margin-top: 0.1rem;
        height: 0.5rem;

        .progress-bar {
          background-color: $tc-red;
        }
      }
    }

    .two {
      .subtitle {
        color: $grey-text;
        font-size: 0.725rem;
        font-weight: 300;
      }

      .check-payment {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        margin: 0.625rem 0;

        .form-check {
          @include flexbox();
          @include align-items(center);

          margin: 0 0.8rem;

          .icon-webpay {
            width: 150px;
          }

          .icon-servipag {
            width: 120px;
          }
        }
      }

      .btn-payment {
        @include flexbox();

        margin: 0.3rem 0;

        .btn-action {
          width: 50%;
          margin: 0 0.5rem;

          .btn-active,
          .btn-outline {
            width: 100%;
          }
        }
      }
    }
    // Queries Seccion 1
    @media (max-width: 992px) {
      grid-template-columns: 1fr;

      .divider {
        width: 95%;
        height: 1px;
      }
    }

    @media (max-width: 796px) {
      .one,
      .two {
        margin: 1rem;
      }

      .one {
        .box-card {
          @include flex-direction(column);

          .form-group {
            width: 100%;
            margin-top: 0.5rem;
          }
        }

        .list-data {
          .box-shadow {
            @include flex-direction(column);

            .more-info {
              max-width: 100%;
              width: 100%;
            }

            .switch {
              margin: 0.5rem 0;
            }

            .btn-action {
              width: 100%;
              text-align: center;
              margin: 0.5rem 0;

              .btn-outline {
                width: 100%;
                margin: 0;
                font-size: 1rem;
              }
            }
          }
        }
      }

      .two {
        .btn-payment {
          @include flex-direction(column);

          .btn-action {
            width: 100%;
            margin: 0.5rem 0;

            .btn-outline {
              margin: 0;
              font-size: 1rem;
            }
          }
        }
      }
    }

    @media (max-width: 576px) {
      .one,
      .two {
        .list-data {
          li {
            // @include flex-direction(column);
            // @include align-items(baseline);
            p {
              &:last-child {
                margin-top: 0.3rem;
                color: $tc-black;
              }
            }
          }
        }
      }

      .one {
        .bar-progress {
          .values {
            span {
              font-size: 0.9rem;
            }

            &:first-child {
              text-align: left;
            }

            &:last-child {
              text-align: right;
            }
          }
        }
      }

      .two {
        h2 {
          text-align: center;
        }

        .check-payment {
          // @include flex-direction(column);
          // @include align-items(baseline);
          @include justify-content(space-around);
          .form-check{
            margin: 0;
            .icon-webpay, .icon-servipag{
              width: 100px;
            }
          }
        }
      }
    }
  }
  // Seccion 2
  .wrapper-two {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .next-payments,
    .last-payments,
    .account-status {
      .title {
        font-size: 1.25rem;
        color: $tc-black;
        font-weight: 500;
        padding: 0.8rem;
        padding-bottom: 0;
      }

      .text {
        color: $tc-black;
        text-align: center;
        font-size: 0.875rem;
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
        max-width: 90%;
      }
      .subscribe{
        max-width: 90%;
        margin: 0 auto;
        padding-top: 0.4rem;
        .btn-action{
          .btn-outline{
            width: 80%;
            margin: 0;
          }
        }
      }

      .btn-action {
        text-align: center;
        margin: 1.25rem 0;
      }
    }

    .next-payments {
      @include flexbox();
      @include flex-direction(column);

      margin: 1rem 0;
    }

    .last-payments {
      margin: 1rem;
    }

    .account-status {
      margin: 1rem 0;
    }
    // Queries Seccion 2
    @media (max-width: 992px) {
      grid-template-columns: 1fr;

      .next-payments,
      .last-payments,
      .account-status {
        margin: 0.5rem 0;

        .title {
          text-align: center;
        }
      }
    }

    @media (max-width: 796px) {
      .next-payments,
      .last-payments,
      .account-status {
        .btn-action {
          .btn-outline {
            width: 90%;
          }
        }
      }

      .account-status {
        .title {
          text-align: center;
        }
        .subscribe{
          .btn-action{
            .btn-outline{
              width: 100%;
            }
          }
        }

        .text {
          max-width: 90%;
          margin: 0.8rem auto;
        }
      }
    }
  }
  // Seccion 3
  .wrapper-three {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 1rem;

    .movements {
      margin: 0 1rem 0 0;

      .title-search {
        padding: 0.8rem;
        padding-bottom: 0;

        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);

        margin-bottom: 0.8rem;

        h3 {
          font-weight: 500;
          color: $tc-black;
          font-size: 1.25rem;
          margin: 0;
        }

        .form-group {
          width: 35%;
          margin: 0;

          input {
            width: 100%;
          }
        }
      }
    }

    .benefits {
      margin: 0;

      .card-lock,
      .club {
        h3 {
          color: $tc-black;
          font-weight: 500;
          font-size: 1.25rem;
          padding: 0.8rem;
          padding-bottom: 0;
        }
      }

      .card-lock {
        margin: 0.5rem 0 0 0;

        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);

        &:hover {
          cursor: pointer;
        }

        .phone {
          .box-phone {
            @include flexbox();
            @include align-items(center);

            padding-left: 0.8rem;

            img {
              width: 24px;
              margin-right: 0.8rem;
            }

            p {
              color: $tc-red;
              font-weight: 500;
              font-size: 1.5rem;
              margin: 0;
              padding-left: 0;
            }
          }

          p {
            color: $tc-black;
            font-weight: 300;
            font-size: 0.75rem;
            padding-left: 0.8rem;
          }
        }

        .lock-icon {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background: #f0f0f0;

          @include flexbox();
          @include align-items(center);
          @include justify-content(center);

          margin: 1rem;
        }
      }

      .club {
        margin: 0 0 1rem 0;

        .list-benefits {
          padding: 0;
          margin: 0;
          list-style: none;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          margin-top: 1rem;

          li {
            margin: 0 0.8rem;
            height: 210px;
            position: relative;
            overflow: hidden;

            @include flexbox();
            @include align-items(center);
            @include justify-content(center);

            .overlay {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: rgba(0, 0, 0, 0.4);
              z-index: 2;
            }

            p {
              color: $tc-white;
              font-weight: 500;
              font-size: 1rem;
              z-index: 3;
              margin: 0;
              text-align: center;
            }

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              right: 0;
              bottom: 0;
              transform: translate(-50%, -50%);
              height: 100%;
            }
          }
        }

        .btn-action {
          margin: 1.2rem 0;
          text-align: center;
        }
      }
    }
    // Queries Seccion 3
    @media (max-width: 992px) {
      grid-template-columns: 1fr;

      .movements {
        margin: 0;
        overflow-x: auto;
      }

      .benefits {
        .club {
          margin-top: 0.5rem;
          margin-bottom: 0;
        }
      }
    }

    @media (max-width: 796px) {
      .movements {
        .title-search {
          @include flex-direction(column);

          .form-group {
            width: 100%;
            margin-top: 0.8rem;
          }
        }
      }
    }

    @media (max-width: 576px) {
      .movements {
        .title-search {
          text-align: center;
        }

        .filterCats {
          padding: 0 0.2rem 2rem 0;
        }
      }

      .benefits {
        .club {
          h3 {
            text-align: center;
          }

          .list-benefits {
            grid-template-columns: 1fr;

            @include justify-content(center);

            margin-top: 0;

            li {
              width: 250px;
              margin: 0.5rem auto;
            }
          }

          .btn-action {
            .btn-outline {
              width: 80%;
            }
          }
        }

        .card-lock {
          @include flex-direction(column);

          .phone {
            text-align: center;

            h3 {
              padding: 0.8rem 0 0 0;
              text-align: center;
            }

            .box-phone {
              padding: 0;
            }

            p {
              margin-bottom: 0;
              padding: 0;
            }
          }

          .lock-icon {
            width: 70px;
            height: 70px;
            margin: 0.5rem;
          }
        }
      }
    }
  }
  // Vistas Ultimos Pagos & Estados de Cuenta
  .status-account {
    padding: 1rem;

    .title {
      text-align: center;
      font-size: 1.563rem;
      margin: 1.5rem 0 0.8rem 0;
    }

    .filtros {
      margin: 1rem auto;
      width: 95%;

      .toggle-filters {
        padding: 0 0.5rem;
        display: none;

        button {
          padding: 0;
          width: 100%;

          @include flexbox();
          @include align-items(center);
          @include justify-content(space-between);

          color: $tc-black;
          text-decoration: none;
          transition: all 0.3s;

          &:focus {
            outline: none;
            box-shadow: none;
          }

          img {
            width: 20px;
            transform: rotate(180deg);
            transition: all 0.3s;
          }
        }

        .collapsed {
          img {
            transform: rotate(0deg);
            transition: all 0.3s;
          }
        }
      }

      .btn-action {
        margin-bottom: 1rem;
      }

      table {
        tbody {
          tr {
            grid-template-columns: 25% 25% 25% 25%;
          }
        }
      }
    }

    .status {
      border-radius: 5px;
      box-shadow: $shadow-default;
      padding: 1rem;
      width: 95%;
      margin: auto;
      margin-bottom: 0;

      table {
        border: $tc-white;

        thead {
          color: $tc-black;
          text-align: center;
          font-size: 0.9rem;
          padding: 1rem 2rem;

          td {
            border-bottom: 1px solid #999;
            padding: 1rem 0;
          }
        }

        tbody {
          color: $grey-text;
          text-align: center;
          font-size: 0.9rem;

          td {
            border-bottom: 1px solid rgba(164, 164, 164, 0.3);
            padding: 1rem 0;

            .btn-download {
              border: none;
              background: transparent;

              img {
                width: 20px;
              }
            }

            .modal-content {
              .modal-header {
                border-bottom: none;

                .modal-title {
                  color: $tc-black;
                  font-size: 1.325rem;
                  font-weight: 400;
                  text-align: center;
                  width: 100%;
                }

                .close {
                  background: rgba(0, 0, 0, 0.8);
                  color: $tc-white;
                  border-radius: 50%;
                  width: 10px;
                  height: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  top: 20px;
                  right: 20px;
                  position: absolute;
                  font-weight: 100;
                }
              }

              .modal-body {
                .list-client {
                  padding: 0;
                  margin: 0;
                  list-style: none;

                  li {
                    @include flexbox();
                    @include justify-content(space-between);
                    @include align-items(center);

                    margin: 0;
                    padding: 0.3rem 0;
                    width: 100%;
                    box-shadow: none;

                    p {
                      color: $tc-black;
                      font-size: 1rem;
                      margin: 0;
                      font-weight: 400;
                      &:last-child{
                        font-weight: 100;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // Queries
    @media (max-width: 796px) {
      .filtros {
        .toggle-filters {
          display: block;
        }

        table {
          tbody {
            tr {
              grid-template-columns: 1fr;

              td {
                text-align: left;

                .form-group {
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .status {
        overflow-x: scroll;

        table {
          overflow-x: auto;
          width: 550px;
        }
      }

      .filterCats {
        padding: 1rem 0.5rem;
      }
    }
  }
}

// Contenido
.content {
  .title {
    text-align: center;
    padding: 2rem 0;
  }

  .withdrawal {
    border: 1px solid $tc-grey;
    border-radius: 5px;
    box-shadow: $shadow-default;

    .stepper {
      margin: 1rem 0;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        li {
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);

          &::after {
            content: '';
            width: 3vw;
            border-top: 1px solid silver;
          }

          &:last-child {
            &::after {
              content: none;
            }
          }

          span {
            border-radius: 40px;
            width: 40px;
            height: 40px;
            margin: 0 0.5rem;
            color: $tc-white;
            background: rgba(0, 0, 0, 0.3);

            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
          }
        }

        .active {
          span {
            background: $tc-red;
            color: $tc-white;
          }
        }
      }
    }

    .wrapper-two {
      display: grid;
      grid-template-columns: 50% 1px 50%;
      padding: 1rem;

      .divider {
        height: 100%;
        width: 1.5px;
        background: $grey-text;
      }

      .one,
      .two {
        margin: 0.5rem;
        padding: 0 4rem;

        .results {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            @include flexbox();
            @include justify-content(space-between);

            margin: 0.5rem 0;

            p {
              margin: 0;
              color: $grey-text;
              font-size: 0.85rem;
            }

            span {
              margin-right: 0.5rem;
              color: $tc-black;
            }
          }
        }

        .title-secure {
          font-size: 0.85rem;
          padding: 1rem 0 0.5rem 0;
          border-bottom: 1px solid $grey-text;
          margin-bottom: 0.8rem;
        }

        .secure {
          li {
            p {
              color: $tc-black;
            }
          }
        }
      }

      .one {
        padding: 1rem;

        p {
          font-size: 0.85rem;
          color: $grey-text;

          span, button {
            color: $tc-red;
            font-size: 1.25rem;
          }
        }

        form {
          .form-group {
            @include flexbox();
            @include justify-content(space-between);
            @include align-items(center);
            @include flex-wrap(wrap);
            text-align: right;

            label {
              font-size: 0.75rem;
              font-weight: 500;
              margin: 0;
            }

            .form-control {
              width: 50%;
            }
          }

          .checkbox {
            display: block;

            .form-check {
              margin: 0.5rem 0;

              @include flexbox();
              @include align-items(center);
              @include justify-content(space-between);

              .form-check-label {
                font-weight: 100;
                font-size: 0.875rem;
              }
            }

            .more-info {
              // position: absolute;
              // top: 0;
              // right: 0;
              @include flexbox();
              @include align-items(center);

              a, button:not(.btn-link) {
                background-color: transparent;
                border: 0px solid transparent;
                color: $tc-red;
                font-size: 0.625rem;
                margin-left: 0.313rem;
              }
            }
          }

          .btn-action {
            padding: 1rem 0;

            .btn-outline {
              width: 50%;
              margin: 0 auto;
              display: block;
            }
          }

          .text-info{
            p{
              font-size: 10px;
            }
            .italic{
              font-style: italic;
              margin-top: 0.5rem;
            }
          }
        }
      }

      .two {
        .values {
          // max-width: 80%;
          text-align: center;

          .title {
            font-size: 1.3rem;
            padding: 0;
            margin-top: 1rem;
            margin-bottom: 0;

            &:first-child {
              margin-top: 0;
            }
          }

          p {
            font-size: 1.4rem;
            margin: 0;
          }

          .red {
            color: $tc-red;
          }
        }

        .text-info {
          p {
            color: #727272;
            font-size: 10px;
          }
          .italic{
            font-style: italic;
            margin-top: 0.5rem;
          }
        }
        .btn-action {
          padding: 1rem 0;
          display: grid;
          grid-template-columns: 45% 45%;
          grid-gap: 10%;
          .btn-active {
            width: 100%;
          }
        }
      }

      @media (max-width: 992px) {
        grid-template-columns: 1fr;

        .one,
        .two {
          margin: 0.5rem 0;

          .results {
            max-width: 100%;
          }

          .title-secure {
            max-width: 100%;
          }

          .values {
            max-width: 100%;
          }
        }

        .one {
          border: none;
        }
      }

      @media (max-width: 576px) {
        .one,
        .two {
          padding: 1rem;

          .btn-action {
            .btn-active,
            .btn-outline {
              width: 100%;
            }
          }

          form {
            .btn-action {
              .btn-active,
              .btn-outline {
                width: 100%;
              }
            }
          }
        }

        .one {
          display: none;
          padding: 0;

          form {
            .form-group {
              @include flex-direction(column);

              align-items: inherit;

              label {
                margin-bottom: 0.5rem;
              }

              .form-control {
                width: 100%;
              }
            }

            .checkbox {
              .form-check {
                display: inherit;

                .more-info {
                  margin-left: -20px;
                }
              }
            }
          }
        }

        .two {
          .results {
            li {
              @include flex-direction(row);
            }
          }

          .secure {
            li {
              flex-direction: inherit;
            }
          }
          
          .btn-action {
            grid-template-columns: none;
            grid-template-rows: 2.5rem 2.5rem;
            grid-gap: 1rem;
          }
        }
      }
    }
  }
}

//Payment

.box-payment {
  max-width: 50%;
  margin: 0 auto;
  padding: 1rem;

  .title {
    text-align: center;
    margin-bottom: 0.938rem;
  }

  .top {
    margin-top: 0.938rem;
  }

  .form-control {
    margin-top: 1.875rem;
  }

  .list-client {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 0.938rem;

    li {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);

      margin: 0.5rem 0;

      p {
        font-size: 0.75rem;
        margin: 0;
        color: $grey-text;

        &:last-child {
          color: $tc-black;
          font-size: 0.875rem;
        }
      }
    }
  }

  .alert-warning {
    background: $alert-warning;
    padding: 1rem;

    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    text-align: center;

    span {
      a {
        color: $tc-red;
        text-decoration: underline;
      }
    }

    p {
      margin: 0 0.3rem 0 0;
      color: $tc-black;
      font-size: 1rem;
    }
  }

  .box-pay {
    @include flexbox();
    @include justify-content(space-between);
    @include flex-wrap(wrap);
    margin: 1rem 0;

    .form-check {
      label {
        font-size: 0.875rem;
        color: $tc-black;
      }
    }

    .other {
      margin: 0;
    }

    .invalid-feedback {
      text-align: right;
    }

    p {
      font-weight: bold;
      font-size: 1rem;
    }
    .info-modal{
      @include flexbox();
      @include align-items(center);
      margin-bottom: 1rem;
      p{
        margin: 0;
      }
      .btn{
        padding: 0;
        margin: 0;
        background: transparent;
        border: none;
        margin-left: 20px;
        &:focus{
          outline: none;
          box-shadow: none;
        }
      }
      .modal{
        .modal-header{
          border: none;
          padding: 0;
          .close{
            background: rgba(0, 0, 0, 0.8);
            color: $tc-white;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 20px;
            right: 20px;
            position: absolute;
            font-weight: 100;
            cursor: pointer;
            z-index: 4;
          }
        }
        .modal-body{
          .text{
            font-size: 0.9rem;
            color: $tc-black;
            font-weight: 300;
            padding-top: 22px;
            strong{
              color: $tc-red;
              text-decoration: underline;
            }
          }
          .list-modal{
            margin: 1rem 0 0 0;
            padding: 0 0 0 15px;
            list-style: disc;
            li{
              p{
                font-size: 0.9rem;
                color: $tc-black;
                font-weight: 300;
              }
            }
          }
        }
      }
    }
  }

  .active {
    .form-check {
      label {
        display: block;

        &:last-child {
          font-weight: 300;
          color: #646464;
          font-size: 0.75rem;
        }
      }
    }

    p {
      color: $tc-red;
    }
  }

  .check-payment {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);

    .form-check {
      @include flexbox();
      @include align-items(center);

      .icon-webpay {
        width: 150px;
      }

      .icon-servipag {
        width: 120px;
      }
    }
  }

  .btn-action {
    margin: 1.5rem 0;
    text-align: center;

    .btn-active {
      width: 50%;
    }
  }

  @media (max-width: 992px) {
    max-width: 80%;
  }

  @media (max-width: 576px) {
    max-width: 100%;

    .list-client {
      li {
        // @include flex-direction(column);
        // @include align-items(flex-start);
        p {
          margin-bottom: 0.3rem;
          &:last-child{
            text-align: right;
          }
        }
      }
    }

    .alert-warning {
      @include flex-direction(column);
    }

    .box-pay {
      // @include flex-direction(column);
      .form-check {
        margin-bottom: 0.5rem;
      }

      .other {
        max-width: 100%;
      }
    }

    .check-payment {
      // @include flex-direction(column);
      // @include align-items(flex-start);

      .form-check {
        margin: 0.5rem 0;
        .icon-webpay, .icon-servipag{
          width: 100px;
        }
      }
    }

    .btn-action {
      .btn-active {
        width: 100%;
      }
    }
  }
}

// Initialize
@import 'styles/utils.scss';
@import 'styles/variables.scss';

//Fonts
@font-face {
  font-family: 'Gotham';
  font-style: medium;
  font-weight: 500;
  src: url(../assets/fonts/woff2/Gotham-Medium.woff2) format('woff2'),
    url(../assets/fonts/woff/Gotham-Medium.woff) format('woff'),
    url(../assets/fonts/ttf/Gotham-Medium.ttf) format('ttf'), url(../assets/fonts/svg/Gotham-Medium.svg) format('svg'),
    url(../assets/fonts/eot/Gotham-Medium.eot) format('eot');
}

@font-face {
  font-family: 'Gotham';
  font-style: light;
  font-weight: 300;
  src: url(../assets/fonts/woff2/Gotham-Light.woff2) format('woff2'),
    url(../assets/fonts/woff/Gotham-Light.woff) format('woff'), url(../assets/fonts/ttf/Gotham-Light.ttf) format('ttf'),
    url(../assets/fonts/svg/Gotham-Light.svg) format('svg'), url(../assets/fonts/eot/Gotham-Light.eot) format('eot');
}

@font-face {
  font-family: 'Gotham';
  font-style: regular;
  font-weight: 400;
  src: url(../assets/fonts/woff2/Gotham-Book.woff2) format('woff2'),
    url(../assets/fonts/woff/Gotham-Book.woff) format('woff'), url(../assets/fonts/ttf/Gotham-Book.ttf) format('ttf'),
    url(../assets/fonts/svg/Gotham-Book.svg) format('svg'), url(../assets/fonts/eot/Gotham-Book.eot) format('eot');
}

@font-face {
  font-family: 'Gotham';
  font-style: bold;
  font-weight: 700;
  src: url(../assets/fonts/woff2/Gotham-Bold.woff2) format('woff2'),
    url(../assets/fonts/woff/Gotham-Bold.woff) format('woff'), url(../assets/fonts/ttf/Gotham-Bold.ttf) format('ttf'),
    url(../assets/fonts/svg/Gotham-Bold.svg) format('svg'), url(../assets/fonts/eot/Gotham-Bold.eot) format('eot');
}

@font-face {
  font-family: 'Gotham';
  font-style: black;
  font-weight: 900;
  src: url(../assets/fonts/woff2/Gotham-Black.woff2) format('woff2'),
    url(../assets/fonts/woff/Gotham-Black.woff) format('woff'), url(../assets/fonts/ttf/Gotham-Black.ttf) format('ttf'),
    url(../assets/fonts/svg/Gotham-Black.svg) format('svg'), url(../assets/fonts/eot/Gotham-Black.eot) format('eot');
}
// General
html {
  font-size: 100%;
}

body {
  font-size: 100% !important;
  padding: 0;
  margin: 0;
  font-family: 'Gotham', sans-serif;
  font-weight: 400;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

// Titulos
.title {
  font-size: 1.25rem;
  font-weight: 400;
}
//Botones
.btn-outline {
  padding: 0.5rem 2rem;
  border: 2px solid $tc-red;
  border-radius: 30px;
  display: inline-block;
  color: $tc-red;
  background: $tc-white;
  transition: 0.2s ease-in;
  cursor: pointer;
  margin: 0 0.5rem;
  text-align: center;
  font-size: 0.84rem;

  &:hover {
    background: $tc-red;
    color: $tc-white;
    text-decoration: none;
  }
}

.btn-active {
  margin: 0 0.5rem;
  background: $tc-red;
  color: $tc-white;
}
// Tabla
.table {
  thead {
    tr {
      th {
        border: none;
        font-size: 0.875rem;
        font-weight: 400;
        color: #a4a4a4;
      }
    }
  }

  td,
  th {
    border: none;
  }

  tbody {
    tr {
      th,
      td {
        font-size: 0.813rem;
        font-weight: 400;
        color: #434343;
      }

      &:nth-child(2n + 1) {
        background: #ececec;
      }
    }
  }
}

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 900;
}

header .header {
  padding: 1rem;
  box-shadow: $shadow-default;
  padding-bottom: 0;
  min-height: 4rem;

  .container-header {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);

    .logo {
      img {
        width: 100px;
      }
    }

    .user {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);

      p {
        margin: 0;
        padding-right: 1rem;
        font-size: 1.2rem;
      }

      .icon-logout {
        border: 1px solid $tc-red;

        @include radius(50%);

        padding: 0.5rem 0.8rem;
      }

      .icon-setting {
        border: 1px solid $tc-red;

        @include radius(50%);

        padding: 0.4rem 0.85rem;
      }
    }
  }
  // Header
  .navbar {
    @include justify-content(center);

    width: 100%;
    margin: 0;
    padding: 0;

    .nav {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);

      position: relative;
      width: 100%;

      .burger-menu {
        display: none;
        background: transparent;
        border: none;
        cursor: pointer;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .logo {
        display: none;
      }

      .nav-list {
        .list {
          margin: 0;
          padding: 0;
          list-style: none;

          @include flexbox();
          @include align-items(center);

          .welcome {
            display: none;
            margin: 0 0 1rem 1.1rem;
          }

          .nav-item {
            padding-top: 1.5rem;

            .nav-link {
              border-bottom: 4px solid transparent;
              padding: 0;
              margin: 0 1.1rem;
              padding-bottom: 1rem;
              color: $tc-black;
              font-weight: 400;
              font-size: 0.875rem;

              &:hover {
                border-bottom: 4px solid $tc-red;
              }
            }
          }
          .active {
            .nav-link {
              border-bottom: 4px solid $tc-red;
            }
          }
        }
      }
    }

    @media (max-width: 992px) {
      .nav {
        .burger-menu {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
        }

        .logo {
          display: block;
        }
      }

      .nav-list {
        display: none;
      }
    }
  }

  .container-sidebar {
    display: none;

    .overlay-sidebar {
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      z-index: 99999999;
      top: 0;
      left: 0;
    }

    .sidebar-nav {
      background: $tc-white;
      position: fixed;
      top: 0;
      left: 0;
      width: 20rem;
      height: 100vh;
      z-index: 99999999;
      overflow-y: auto;
      overflow-x: hidden;

      @include flexbox();
      @include flex-direction(column);

      box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);

      .sidebar-header {
        padding: 1.5rem 1rem;
        box-shadow: $shadow-default;

        @include flexbox();
        @include align-items(center);

        .welcome {
          margin: 0;
          font-size: 1rem;
        }
      }

      .sidebar-ul {
        margin-top: 1rem;
        padding: 0;
        list-style: none;
        border-bottom: 1px solid hsla(0, 0%, 60%, 0.31373);

        .sidebar-li {
          display: flex;

          .sidebar-li-item {
            padding: 0.8rem 1rem;
            border-left: 4px solid transparent;
            color: $tc-black;
            transition: 0.2s ease-in;

            &:hover {
              border-left: 4px solid $tc-red;
              text-decoration: none;
            }
          }

          .active {
            border-left: 4px solid $tc-red;
          }
        }
      }

      .sidebar-footer {
        text-align: center;

        .btn-outline {
          width: 80%;
        }
      }
    }
  }

  .close-sidebar {
    display: none;

    .overlay-sidebar {
      animation: 0.3s c ease-in-out;
      opacity: 0;
      visibility: hidden;
    }

    .sidebar-nav {
      animation: 0.3s b ease-in-out;
      left: 0;
      opacity: 1;
      visibility: hidden;
    }
  }

  .show-sidebar {
    display: block;

    .overlay-sidebar {
      animation: 0.3s e ease-in-out;
    }

    .sidebar-nav {
      left: 0;
      animation: 0.3s d ease-in-out;
    }
  }

  @media (max-width: 992px) {
    padding: 1rem;

    .container-header {
      display: none;
    }
  }
}

// Swiper
.swiper-button-prev, .swiper-button-next {
  color: $tc-red !important;
  &::after{
    font-size: 1.5rem !important;
  }
}
.swiper-button-prev {
  left: 0px !important;
}
.swiper-button-next {
  right: 0px !important;
}
// Banner
.container-banner {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);

  margin: 1.3rem 0;
  .swiper-container{
    padding-bottom: 20px;
    width: 100%;
    .swiper-button-prev, .swiper-button-next{
      top: 50%;
      transform: translate(-0px, -25%);
    }
    .swiper-pagination{
      bottom: -5px;
      .swiper-pagination-bullet-active{
        background: $tc-red !important;
      }
    }
  }
  .banner {
    color: $tc-black;
    cursor: pointer;
    width: 33%;
    border: 1px solid $tc-grey;
    max-height: 100px;
    min-height: 100px;
    overflow: hidden;
    text-decoration: none;    
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    margin-right: 5px;

    @include radius(5px);
    @include flexbox();
    @include flex-direction(row-reverse);
    @include align-items(flex-start);
    @include justify-content(space-between);

    img {
      width: 50%;
      overflow: hidden;
    }

    p {
      margin: 0;
    }

    .banner-text {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 500;
      min-height: 100px;
      width: 50%;
    }
  }

  @media (max-width: 768px) {
    @include flex-direction(column);

    .banner {
      width: 100%;
      margin: 0.5rem 0;
    }
  }
}

// Contenido
.content {
  .col-lg-8 {
    padding-right: 15px;
  }

  .credit-card,
  .account,
  .movements {
    box-shadow: $shadow-default;
    border: 1px solid $tc-grey;

    @include radius(5px);
  }

  .credit-card {
    width: 100%;
    padding: 1rem;

    .info-card {
      @include flexbox();

      .tricard {
        width: 35%;
        margin: 0 0.5rem;

        img {
          width: 100%;
          max-width: 100%;
        }
      }

      .info {
        width: 65%;

        .vertical {
          @include flexbox();
          @include justify-content(space-between);

          margin: 0.5rem 0;

          span {
            font-size: 0.8rem;

            &:last-child {
              font-size: 1.1rem;
            }
          }
        }

        .debt-total,
        .text {
          @include flexbox();
          @include flex-direction(column);

          width: 33.3%;
        }

        .debt-total {
          span {
            &:last-child {
              color: $tc-red;
            }
          }
        }
      }
    }

    .buttons {
      margin: 1.5rem 0 0.5rem 0;

      @include flexbox();
      @include justify-content(space-between);

      a {
        width: 33.3%;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    @media (max-width: 992px) {
      .info-card {
        @include flex-direction(column);

        .tricard {
          margin: 0 auto;
        }

        .info {
          margin-top: 1rem;
          width: 100%;
        }
      }

      .buttons {
        @include flex-direction(column);

        a {
          margin: 0.5rem 0;
          width: 100%;
        }
      }
    }

    @media (max-width: 576px) {
      .info-card {
        .tricard {
          width: 100%;
        }

        .info {
          .vertical {
            @include flex-direction(column);

            .debt-total,
            .text {
              width: 100%;
              border-bottom: 1px solid $tc-grey;
              padding: 0.5rem 0;
            }
          }
        }
      }
    }
  }

  .account {
    margin: 1rem 0;
    padding: 1rem;

    .form {
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);

      .title {
        font-size: 1rem;
        margin: 0;
      }

      #email {
        width: 30%;

        @include radius(5px);

        border: 1px solid #a4a4a4;
      }
    }

    @media (max-width: 576px) {
      .table {
        thead {
          tr {
            th {
              &:nth-child(2n) {
                display: none;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              &:nth-child(2n) {
                display: none;
              }
            }
          }
        }
      }

      .form {
        @include flex-direction(column);

        .title,
        #email,
        input {
          width: 100%;
        }

        #email {
          margin: 0.8rem 0;
        }
      }
    }
  }

  .movements {
    padding: 1rem;

    .search {
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
    }

    .movements-btn {
      border-top: 1px solid $tc-grey;
      text-align: right;
      padding: 0.8rem 0 0 0;

      a {
        font-size: 1rem;
        color: $tc-red;
      }
    }

    @media (max-width: 768px) {
      .search {
        @include flex-direction(column);

        .title {
          width: 100%;
        }

        .form-inline {
          width: 100%;

          input {
            width: 100%;
          }
        }
      }

      .movements-btn {
        text-align: center;
      }
    }

    @media (max-width: 576px) {
      .table {
        thead {
          tr {
            th {
              &:nth-child(2n) {
                display: none;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              &:nth-child(2n) {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .next-payment,
  .last-payments,
  .club-benefits,
  .card-lock {
    box-shadow: $shadow-default;
    border: 1px solid $tc-grey;

    @include radius(5px);

    margin-bottom: 1rem;

    .title {
      padding: 1rem;
    }

    .graphic {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);

      min-height: 200px;
    }
  }

  .last-payments {
    margin: 1rem 0;
  }

  .club-benefits {
    min-height: 300px;
  }

  .card-lock {
    padding: 1rem;

    .title {
      padding: 0;
    }

    .container-lock {
      @include flexbox();
      @include justify-content(space-between);

      .phone {
        @include flexbox();
        @include align-items(center);

        p {
          margin: 0 0 0 1.2rem;
          font-size: 1.5rem;
          color: $tc-red;
        }
      }

      .lock {
        background: #f0f0f0;

        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
    }

    .schedule {
      margin: 1.2rem 0;
      font-size: 0.75rem;
    }

    @media (max-width: 576px) {
      .container-lock {
        .phone {
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .col-lg-8 {
      padding: 0;
    }
  }
}

//Footer
footer {
  background: #1f1f1f;
  margin-top: 2.5rem;

  .row {
    padding: 2rem;

    .list-footer {
      padding: 0;
      margin: 0;
      list-style: none;

      .title-footer {
        font-size: 1rem;
        font-weight: 400;
        color: $tc-white;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
      }

      li {
        a,
        p {
          color: #8c8c8c;
          font-size: 0.75rem;
          line-height: 1.8rem;
          margin: 0;
        }
      }
    }
  }

  .subfooter {
    background: rgba(0, 0, 0, 0.2);
    min-height: 80px;

    @include flexbox();
    @include align-items(flex-start);

    font-size: 0.75rem;
    font-weight: 400;
    padding: 0 2rem;
    flex-direction: column;
    justify-content: end;

    p {
      color: $tc-white;
    }
    a {
      color: $tc-white;
    }
  }

  @media (max-width: 768px) {
    .row {
      .list-footer {
        margin: 1rem 0;
      }
    }
  }
}

// Modales
.modal-footer {
  border-top: 0;
}
// Modal Reordena tu deuda
.debt{
  .box-simulate{
    .two{
      .accept{
        label{
          .modal-header {
            @include justify-content(center);

            padding-bottom: 0;
            border-bottom: none;

            .modal-title {
              color: $tc-black;
              font-size: 1.125rem;
              font-weight: 100;
            }

            .close {
              background: rgba(0, 0, 0, 0.8);
              border-radius: 50%;
              width: 10px;
              height: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              top: 20px;
              right: 20px;
              position: absolute;
              font-weight: 100;
              
              span {
                color: $tc-white;
              }
            }
          }
        }
      }
    }
  }
}
.one, .three, .two {
  .list-box {
    li, .form-check {
      .header-box {
        .modal {
          &.modal-seguro {
            text-align: left;

            .modal-dialog {
              max-width: 1000px;

              .modal-header {
                .modal-title {
                  font-size: 1.75rem;
                  color: $tc-black;
                  text-align: center;
                }

                .close {
                  span {
                    font-weight: 100;
                  }
                }
              }

              .modal-body {
                padding: 1rem 3.5rem 3rem;

                & > ul, ol {
                  padding: 0;
                  margin: 0.5rem 0;
                  list-style: none;

                  @include flexbox();

                  li {
                    text-align: center;
                    box-shadow: none;
                    border: none;
                    border-bottom: 3px solid transparent;
                    border-radius: 0;
                    padding: 0.5rem 0;
                    width: 100%;

                    .link {
                      font-weight: 500;
                      font-size: 1.25rem;
                      text-decoration: none;
                      color: $grey-text;
                      opacity: 0.3;
                      display: block;
                    }

                    &:hover {
                      // border-bottom: 3px solid $tc-red;

                      .link {
                        color: $tc-red;
                        border-color: transparent;
                        opacity: 1;
                      }
                    }
                  }

                  .active {
                    border-bottom: 3px solid $tc-red;

                    .link {
                      color: $tc-red;
                      opacity: 1;
                    }
                  }
                }

                .arrow {
                  position: absolute;
                  right: 0;
                  top: 0;
                  width: 60px;
                  height: 100%;
                  font-size: 18px;
                  color: #555;
                  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, #fff 40%, #fff);
                  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#00ffffff", endColorstr="#ffffff", GradientType=1);
                  z-index: 3;
                  pointer-events: none;
                  display: flex;
                  -moz-align-items: center;
                  -ms-align-items: center;
                  align-items: center;
                  -moz-justify-content: center;
                  -ms-justify-content: center;
                  justify-content: center;
                  -ms-flex-pack: center;

                  span {
                    img {
                      width: 20px;
                    }
                  }
                }

                .info-modal {
                  .info.card-body {
                    margin: 0;
                  }

                  .info {
                    margin: 0.8rem 0;

                    h4 {
                      color: $tc-black;
                      font-size: 1.125rem;
                      font-weight: 300;
                    }

                    h5 {
                      color: $tc-black;
                      font-size: 0.9rem;
                      font-weight: 300;
                    }

                    p {
                      font-size: 0.875rem;
                      font-weight: 300;
                      color: $grey-text;
                    }

                    ul, ol {
                      display: block;
                      font-size: 0.875rem;
  
                      li {
                        box-shadow: none;
                        margin: 0.75rem 1.5rem;
                        padding: 0;
                        width: auto;
                      }
                    }
                  }

                  .accordion {
                    .card {
                      border-radius: 0;
                    }
                    .card-header {
                      background: transparent;

                      h4 {
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(space-between);

                        &:hover {
                          cursor: pointer;
                        }

                        p {
                          color: $tc-black;
                          font-size: 1.125rem;
                          font-weight: 300;
                        }
                      }

                      .btn-link {
                        background-image: linear-gradient(45deg, transparent 50%, gray 50%),
                          linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
                        background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
                          calc(100% - 2.5em) 0.5em;
                        background-size: 5px 5px, 5px 5px, 1px 1.5em;
                        background-repeat: no-repeat;
                        font-size: 0;
                      }
                    }

                    .card-body {
                      font-size: 0.875;
                      font-weight: 300;
                      color: $grey-text;
                    }
                  }
                }
              }
            }
          }

          .modal-header {
            @include justify-content(center);

            padding-bottom: 0;
            border-bottom: none;

            .modal-title {
              color: $tc-black;
              font-size: 1.125rem;
              font-weight: 100;
            }

            .close {
              background: rgba(0, 0, 0, 0.8) !important;
              border-radius: 50%;
              width: 10px;
              height: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              top: 20px;
              right: 20px;
              position: absolute;
              font-size: 1.625rem !important;
              font-weight: 100;
              
              span {
                color: $tc-white;
              }
            }
          }

          .modal-body {
            .list-client {
              padding: 0;
              margin: 0;
              list-style: none;

              li {
                @include flexbox();
                @include justify-content(space-between);
                @include align-items(center);

                margin: 0;
                padding: 0.3rem 0;
                width: 100%;
                box-shadow: none;

                p {
                  color: $tc-black;
                  font-size: 1rem;

                  strong {
                    color: $tc-red;
                    font-weight: 400;
                  }
                }
              }
            }

            form {
              .form-group {
                @include flexbox();
                @include justify-content(space-between);
                @include align-items(center);

                label {
                  font-size: 0.75rem;
                  font-weight: 500;
                  margin: 0;
                }

                .form-control {
                  width: 50%;
                }
              }

              .checkbox {
                display: block;

                .form-check {
                  margin: 0.5rem 0;

                  @include flexbox();
                  @include align-items(center);
                  @include justify-content(space-between);

                  .form-check-label {
                    font-weight: 100;
                    font-size: 0.875rem;
                  }
                }

                .accept {
                  @include align-items(baseline);

                  padding-top: 0.5rem;

                  a {
                    margin-left: 0.3rem;
                    color: $tc-red;
                  }
                  .terms{
                    background: transparent;
                    border: none;
                    color: $tc-red;
                    text-decoration: underline;
                  }
                }

                .more-info {
                  @include flexbox();
                  @include align-items(center);

                  button:not(.btn-link) {
                    font-size: 0.625rem;
                    color: $tc-red;
                    margin-left: 0.313rem;
                    background-color: transparent;
                    border: none;
                  }
                }
              }
            }

            .btn-action {
              text-align: center;
              margin: 0.3rem 0;

              .btn-active {
                width: 60%;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 796px) {
    .list-box {
      li, .form-check {
        .header-box {
          .modal {
            &.modal-seguroo {
              .modal-dialog {
                .modal-body {
                  padding: 1rem !important;  

                  ul, ol {
                    flex-wrap: nowrap;
                    overflow: scroll;

                    li {
                      margin: 0.75rem !important;
                      flex: 0 0;

                      // .link {
                      //   font-size: 1.25rem;
                      // }
                    }
                  }

                  .arrow {
                    display: flex;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//Documentos

.wrapper-docs {
  .suscritos {
    .filtros {
      margin: 0;

      table {
        tr {
          text-align: left;

          td {
            border: none;
          }

          .box-search {
            label {
              font-size: 0;
              display: none;
            }

            .btn-action {
              margin-top: 0.3rem;

              button {
                width: 100%;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  .estados-cuenta {
    .filtros {
      table {
        tr {
          display: grid;
          grid-template-columns: 33% 33% 33%;

          @include align-items(center);

          td {
            .form-group {
              @include flexbox();
              // @include align-items(center);
              @include flex-direction(column);

              margin: 0;
            }

            .m-t {
              height: 15px;
              font-size: 0;
              display: block;
            }
          }

          .box-search {
            .m-b {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  .list-docs {
    @include flexbox();
    flex-wrap: wrap;
    gap: 1rem 0.5rem;
    margin: 1rem auto;
    width: 90%;
    padding: 0;

    li {
      display: inline-block;
      border-radius: 8px;
      padding: 1rem;
      margin: 0 0.5rem 0.5rem 0;
      width: 23.5%;
      box-shadow: $shadow-default;

      h4 {
        font-size: 1rem;
        color: #333;
      }

      p {
        font-size: 0.8rem;
        color: $grey-text;
      }

      hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      button {
        font-size: 0.7rem;
        padding: 0;
        color: $grey-text;
        border: none;
        background: transparent;
      }
    }
  }

    .modal-dialog {
      max-width: 100%;

      .modal-content {
        .modal-header {
          padding-bottom: 0;
          border-bottom: none;

          .modal-title {
            font-weight: 500;
            color: $tc-black;
            font-size: 1.563rem;
          }

          .close {
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 20px;
            right: 20px;
            position: absolute;

            span {
              color: $tc-white;
            }
          }
        }

        .modal-body {
          display: grid;
          grid-template-columns: 50% 50%;

          .box-img {
            background: $tc-grey;
            padding: 1rem;
            overflow-y: scroll;
            max-height: 550px;

            img {
              width: 100%;
            }
          }

          .box-info {
            padding: 0 1rem 1rem 1rem;
            text-align: center;

            .title {
              font-size: 1.25rem;
              color: $tc-black;
              font-weight: 500;
            }

            p {
              margin: 0.5rem 0;
              font-size: 1rem;
              color: $grey-text;
            }

            .form-group {
              text-align: left;
              margin-bottom: 0.3rem;
            }

            .btn-action {
              padding: 0.5rem 0;

              .btn-active,
              .btn-outline {
                width: 60%;
              }
            }
            .btn-table {
              border: none;
              background: transparent;
  
              &:focus {
                outline: none;
                box-shadow: none;
              }
  
              img {
                width: 20px;
              }
            }
          }
        }
      }
    }

  @media (max-width: 992px) {
    .list-docs {
      width: 100%;

      li {
        width: 45%;
        margin: 0.5rem;
      }
    }

    .modal-dialog {
      .modal-content {
        .modal-body {
          grid-template-columns: 1fr;
          iframe{
            height: 350px !important;
          }

          .box-info {
            margin-top: 1.5rem;
          }
        }
      }
    }
  }

  @media (max-width: 796px) {
    .suscritos {
      .filtros {
        table {
          tbody {
            padding: 0;
          }

          tr {
            margin: 0;

            td {
              text-align: left;

              .form-group {
                margin: 0;
              }
            }

            .box-search {
              margin-bottom: 1rem;

              label {
                display: none;
              }

              .btn-action {
                button {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .estados-cuenta {
      .filtros {
        table {
          tr {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .list-docs {
      text-align: center;
      @include flex-direction(column);

      li {
        text-align: left;
        width: 90%;
        margin: 0.5rem auto;
      }
    }

    .modal-dialog {
      max-width: 90%;
      margin: 1rem auto;

      .modal-content {
        .modal-body {
          .box-info {
            text-align: center;
            overflow-y: scroll;
            height: 50vh;

            .btn-action {
              .btn-active,
              .btn-outline {
                width: 100%;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

//Patpass

.wrapper-patpass,
.wrapper-docs {
  .secciones {
    margin: 2rem 0;
    position: relative;

    .swiper-button-prev, .swiper-button-next {
      display: none;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      @include flexbox();
      @include align-items(center);
      @include justify-content(center);

      li {
        padding: 0 1rem;
        font-size: 0.9rem;

        span {
          margin: 0 0.5rem;
          color: $grey-text;

          @include flexbox();
          @include align-items(center);
          @include justify-content(center);

          cursor: pointer;
          border-bottom: 2px solid transparent;
          line-height: 2rem;

          &:hover {
            border-bottom: 2px solid $tc-red;
            color: $tc-red;
          }
        }

        .active {
          border-bottom: 2px solid $tc-red;
          color: $tc-red;
        }
      }
    }

    .arrow {
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      height: 100%;
      font-size: 18px;
      color: #555;
      background: -moz-linear-gradient(left, hsla(0, 0%, 100%, 0) 0, #fff 40%, #fff 100%);
      background: -webkit-linear-gradient(left, hsla(0, 0%, 100%, 0), #fff 40%, #fff);
      background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0, #fff 40%, #fff);
      filter: progid:dximagetransform.microsoft.gradient(startColorstr="#00ffffff", endColorstr="#ffffff", GradientType=1);
      z-index: 3;
      pointer-events: none;

      @include flexbox();
      @include align-items(center);
      @include justify-content(center);

      display: none;

      span {
        img {
          width: 20px;
        }
      }
    }
  }

  .suscritos {
    border: 1px solid $tc-grey;
    border-radius: 5px;
    box-shadow: $shadow-default;
    padding: 1rem;
    width: 95%;
    margin: 1rem auto;
    margin-bottom: 0;

    table {
      border: $tc-white;

      thead,
      tbody {
        color: $tc-black;
        text-align: center;
        font-size: 0.9rem;
        padding: 1rem 2rem;

        td {
          border-bottom: 1px solid #999;
          padding: 1rem 0;

          .btn-table {
            border: none;
            background: transparent;

            &:focus {
              outline: none;
              box-shadow: none;
            }

            img {
              width: 20px;
            }
          }

          .modal-edit {
            .modal-dialog {
              max-width: 670px;
            }

            .modal-header {
              @include justify-content(center);

              padding-top: 1.85rem;

              .title {
                color: $tc-black;
                font-weight: 300;
                font-size: 1.563rem;
                text-align: center;
              }
            }

            .modal-body {
              .box-info {
                form {
                  max-width: 80%;
                  margin: 0 auto;

                  .form-group {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    select,
                    input {
                      width: 50%;
                    }
                  }
                }
              }
            }
          }

          .modal {
            .modal-header {
              border-bottom: none;
              position: relative;

              .close {
                position: absolute;
                right: 20px;
                top: 20px;
                background: rgba(0, 0, 0, 0.8);
                color: $tc-white;
                width: 10px;
                height: 10px;

                @include flexbox();
                @include align-items(center);
                @include justify-content(center);

                border-radius: 50%;

                span {
                  font-weight: 100;
                }
              }
            }

            .modal-body {
              .box-info {
                text-align: center;

                .warning-icon {
                  width: 120px;
                }

                .title-modal {
                  font-weight: 500;
                  color: $tc-black;
                  font-size: 1.25rem;
                  margin: 25px 0 10px 0;
                }

                p {
                  font-size: 1rem;
                }

                .btn-modal {
                  @include flexbox();
                  @include justify-content(center);

                  margin: 1.5rem 0;

                  .btn-action {
                    padding: 0 0.5rem;
                    width: 40%;

                    .btn-active,
                    .btn-outline {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      tbody {
        color: $grey-text;
        text-align: center;
        font-size: 0.9rem;

        td {
          border-bottom: 1px solid rgba(164, 164, 164, 0.3);
        }
      }
    }

    .filtros {
      margin: 0 3rem;
      font-size: 0.8rem;

      .toggle-filters {
        padding: 0 0.5rem;
        display: none;

        button {
          padding: 0;
          width: 100%;

          @include flexbox();
          @include align-items(center);
          @include justify-content(space-between);

          color: $tc-black;
          text-decoration: none;
          transition: all 0.3s;

          &:focus {
            outline: none;
            box-shadow: none;
          }

          img {
            width: 20px;
            transform: rotate(180deg);
            transition: all 0.3s;
          }
        }

        .collapsed {
          img {
            transform: rotate(0deg);
            transition: all 0.3s;
          }
        }
      }

      table {
        width: 100%;

        td {
          padding: 0.5rem;
        }

        .btn-search {
          label {
            font-size: 0;
            height: 12px;
            display: block;
          }

          .btn-action {
            text-align: center;

            .btn-active {
              width: 80%;
            }
          }
        }
      }
    }
  }

  .suspendido {
    .suscritos {
      .filtros {
        margin: 1rem 0;

        table {
          tr {
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;

            @include align-items(center);

            input,
            select {
              font-size: 0.8rem;
              color: $grey-text;
            }
          }
        }
      }
    }
  }

  .suscribir-pagos {
    .wrapper-one,
    .wrapper-two,
    .wrapper-three {
      display: grid;
      grid-template-columns: 50% 1px 50%;
      padding: 1rem;

      .one,
      .two {
        margin: 2rem;

        .title {
          margin-bottom: 1.25rem;
          color: $tc-black;
        }
      }

      .one {
        p {
          font-size: 0.875rem;
          max-width: 80%;
        }

        .form-group {
          margin: 2.188rem 0 0 0;
          max-width: 80%;
        }
      }

      .two {
        .form-group {
          @include flexbox();
          @include align-items(center);
          @include justify-content(space-between);

          label {
            color: $tc-black;
            font-weight: 500;
            font-size: 0.75rem;

            &:last-child {
              font-weight: 400;
            }
          }

          .form-control {
            max-width: 50%;
          }
        }

        .edit-email {
          .box-email {
            @include flexbox();
            @include align-items(center);
            @include justify-content(space-between);

            width: 50%;

            p {
              margin: 0;
            }

            a {
              img {
                width: 30px;
              }
            }
          }
        }

        .btn-action {
          text-align: center;
          padding-top: 1rem;

          .btn-active {
            width: 50%;
          }
        }
      }
      .three{
        grid-column: 1 / span 3;
        text-align: center;
        margin: 1rem 0;
        .btn-action{
          .btn-outline{
            width: 30%;
          }
        }
      }
    }

    .wrapper-two {
      .one {
        .credit-number {
          max-width: 80%;

          @include flexbox();
          @include align-items(center);
          @include justify-content(space-between);

          margin-top: 1.875rem;

          p {
            &:last-child {
              font-weight: bold;
            }
          }
        }
      }

      .two {
        .form-group {
          display: grid;
          grid-template-columns: 50% 50%;
        }

        .btn-action {
          text-align: left;

          .btn-active,
          .btn-outline {
            width: 45%;
            margin: 0.5rem;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .container {
      .alert {
        margin-bottom: 0;
      }

      .wrapper-three {
        .one,
        .two {
          margin: 1rem;

          .form-group {
            display: grid;
            grid-template-columns: 50% 50%;
            margin: 15px 0 0 0;

            &:first-child {
              margin-top: 0;
            }

            label {
              color: $tc-black;
              font-weight: 500;
              font-size: 0.75rem;

              &:last-child {
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .suscribir-pagos {
      .stepper {
        margin-bottom: 0;
      }

      .wrapper-one,
      .wrapper-two,
      .wrapper-three {
        grid-template-columns: 1fr;
        .three{
          grid-column: 1;
        }

        .one,
        .two {
          margin: 1rem;
        }
        .three{
          grid-column: 1;
        }

        .one {
          p,
          .form-group {
            max-width: 100%;
          }

          .form-group {
            margin-top: 1rem;
          }

          .credit-number {
            max-width: 100%;
          }
        }
      }

      .wrapper-two {
        .two {
          .form-group {
            label {
              &:last-child {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 796px) {
    .suscribir-pagos{
      .wrapper-three{
        .three{
          .btn-action{
            .btn-outline{
              width: 100%;
            }
          }
        }
      }
    }
    .secciones {
      margin: 1rem;

      .swiper-button-prev, .swiper-button-next {
        display: flex;
      }

      ul {
        display: -webkit-box;

        li {
          margin: 0.5rem 0;
          padding: 0 2rem;
          text-align: center;
        }
      }
    }

    .suscritos {
      .filtros {
        margin: 0;

        .toggle-filters {
          display: block;
        }

        table {
          width: 100%;
          // display: flex;
          flex-direction: column;

          tr {
            display: flex;
            flex-direction: column;

            .btn-search {
              label {
                display: none;
              }

              .btn-action {
                margin: 1rem 0;

                .btn-outline {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      table {
        margin-top: 1rem;
      }
    }

    .suspendido {
      .suscritos {
        .filtros {
          table {
            tr {
              grid-template-columns: 1fr;
            }
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .suscritos {
      table {
        tbody {
          td {
            .modal {
              .modal-body {
                .box-info {
                  .btn-modal {
                    @include flex-direction(column);

                    .btn-action {
                      width: 100%;
                      margin: 0.3rem 0;
                    }
                  }
                }
              }
            }

            .modal-edit {
              .modal-body {
                .box-info {
                  form {
                    max-width: 100%;

                    .form-group {
                      @include flex-direction(column);
                      @include align-items(baseline);

                      select,
                      input {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .suscribir-pagos {
      .wrapper-one {
        .two {
          .form-group {
            @include flex-direction(column);
            @include align-items(flex-start);

            .form-control {
              max-width: 100%;
            }
          }

          .edit-email {
            .box-email {
              width: 100%;
            }
          }

          .btn-action {
            .btn-outline {
              width: 100%;
              margin: 0;
            }
          }
        }
      }

      .wrapper-two {
        .one{
          display: none;
        }
        .two {
          .form-group {
            // grid-template-columns: 1fr;
            label {
              &:last-child {
                text-align: right;
              }
            }
          }

          .btn-action {
            @include flexbox();
            @include flex-direction(column);

            padding: 0;

            .btn-active,
            .btn-outline {
              width: 100%;
              margin: 0 0 1rem 0;
            }
          }
        }
      }

      .container {
        .wrapper-three {
          .one,
          .two {
            .form-group {
              // grid-template-columns: 1fr;
              label {
                &:last-child {
                  text-align: right;
                }
              }
            }
          }

          .two {
            margin-top: 0;
          }
        }
      }
    }
  }
}

// Beneficios

.beneficios {
  padding: 2rem 2rem 0 2rem;

  .title-beneficios {
    font-size: 1.25rem;
    text-align: center;
    font-weight: 400;
    margin: 1rem 0;
  }

  .filterCat-find {
    padding: 0.5rem 0 0 0;
    margin: 0;

    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);

    text-align: center;
    width: 100%;

    li {
      display: inline-block;
      font-size: 0.84rem;
      margin: 1rem 12.5rem 1rem 0;
      width: 40%;

      &:last-child {
        margin: 1rem 0 1rem 0;
        display: flex;
        justify-content: end;

        .form-group {
          margin-bottom: 0;
          width: 90%;
        }
      }
    }

    .form-buscar {
      display: block;
      width: 90%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: $tc-white;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      float: left;
      margin: 0 2px 0 0;
    }

    .bot-buscar {
      border: none;
      padding: 0.4rem 0.75rem;
      border-radius: 0.3rem;
      width: 19%;
      color: $tc-red;
      font-size: 1px;
      background: $tc-red;
      // background: #f30000 url(../../public/assets/img/search.svg) no-repeat center center;
      img{
        width: 20px;
      }
    }
  }

  .container-box {
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    grid-gap: 13px;

    .box-beneficios {
      width: 100%;
      border: 1px solid $tc-grey;
      min-height: 70px;
      border-radius: 5px;
      box-shadow: $shadow-default;
      margin: 0;
      text-align: center;

      .box-img {
        overflow: hidden;

        img {
          border-radius: 5px 5px 0 0;
          -webkit-transition: -webkit-transform 5s;
          transition: transform 5s;
        }
      }

      h1 {
        margin: 0.5rem auto;
        font-size: 1.25rem;
      }

      p {
        margin: 0.5rem 0 0 0;
        font-size: 0.85rem;
        width: 95%;
      }

      .more {
        text-align: center;
        display: block;
        color: $tc-red;
        cursor: pointer;
        margin: 0.6rem auto;
        font-size: 1rem;
        padding: 0.2rem 0.5rem;
        font-weight: 500;
        text-decoration: underline;

        .btn-benefits {
          margin: 0 0.5rem;
        }
      }

      &:hover {
        img {
          -webkit-transform: scale(1.3) rotate(0.01deg);
          transform: scale(1.3) rotate(0.01deg);
        }
      }
    }
  }

  .filterCats {
    padding: 1.875rem;
    display: table;
    text-align: center;
    width: auto;
    margin: 0 auto;

    li {
      display: inline-block;
      font-size: 0.84rem;

      a {
        border: 1px solid rgba(164, 164, 164, 0.2);
        font-weight: 500;
        margin: 0.6rem 0.4rem;
        padding: 0.6rem 1rem;
        transition: 0.2s ease-in;
        cursor: pointer;
        text-decoration: none;
        color: $grey-text;

        &:hover {
          background: $tc-red;
          color: $tc-white;
        }
      }

      .active {
        background: $tc-red;
        color: $tc-white;
      }
    }
  }

  @media (max-width: 992px) {
    .filterCat-find {
      li {
        margin: 0;
        width: 49%;
      }
    }

    .container-box {
      grid-template-columns: 49% 49%;
    }
  }

  @media (max-width: 576px) {
    padding: 1rem;

    .title-beneficios {
      font-size: 1rem;
    }

    .filterCat-find {
      @include flex-direction(column);

      li {
        width: 100%;
      }
    }

    .container-box {
      grid-template-columns: 1fr;
    }

    .filterCats {
      padding: 1.875rem 0;
    }
  }
}

// Detalle de beneficios
.beneficios-detail {
  .content {
    .content-img {
      max-height: 285px;
      overflow: hidden;
    }

    .title-detail {
      color: $tc-black;
      font-weight: 500;
      margin-top: 2rem;
      font-size: 2.188rem;
      text-align: center;
    }

    .wrapper {
      display: grid;
      grid-template-columns: 50% 1px 50%;

      .one,
      .two {
        padding: 1rem 2rem;

        img {
          margin-bottom: 1rem;
          width: 100%;
        }

        h4 {
          text-align: left;
          color: $tc-black;
          font-weight: 500;
          font-size: 1.25rem;
          margin-bottom: 1.125rem;
        }

        .btn {
          margin: 0 0 1rem 0;
          color: $tc-white;
        }
      }

      .one {
        p {
          font-weight: 100;
          color: $grey-text;
          font-size: 0.875rem;
          margin: 0 0 0.5rem 0;
        }

        #accordion {
          .card-header {
            padding: 0.75rem 0.5rem;
            background: $tc-white;
            width: 95%;
            margin: 0 auto;

            .mb-0 {
              @include flexbox();
              @include align-items(center);
              @include justify-content(space-between);

              p {
                margin: 0;
              }

              button {
                background-image: linear-gradient(45deg, transparent 50%, gray 50%),
                  linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
                background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
                  calc(100% - 2.5em) 0.5em;
                background-size: 5px 5px, 5px 5px, 1px 1.5em;
                background-repeat: no-repeat;
                font-size: 0;

                &:focus {
                  outline: none;
                  box-shadow: none;
                }
              }
            }
          }

          #collapseOne {
            .card-body {
              font-size: 0.75rem;
              color: $grey-text;
            }
          }
        }
      }

      .two {
        select {
          margin-bottom: 1rem;
        }

        h3 {
          font-size: 1.125rem;
          color: $tc-black;
          font-weight: 500;
          margin-bottom: 0.375rem;
        }

        p {
          font-size: 0.875rem;
          color: $grey-text;
          font-weight: 100;
          margin: 0 0 0.5rem 0;
        }

        .footer-social {
          @include flexbox();

          margin-top: 0.5rem;

          a {
            margin-right: 10px;

            &:last-child {
              margin-right: 0;
            }

            img {
              width: 35px;
            }
          }
        }
      }
    }
  }

  // Estilos nuevos Detalle Beneficios
  .section{
    padding: 0 1rem !important;
    .section-content{
      .slider-wrapper{
        .slider{
          position: inherit !important;
          background: transparent !important;
          .section{
            padding: 0 !important;
            .img-inner{
              padding-top: 0 !important;
              img{
                width: 100% !important;
                height: 100% !important;
              }
            }
          }
        }
      }
      .col-inner{
        margin-bottom: 0.8rem;
        h3{
          margin-bottom: 1rem;
        }
        .text{
          padding: 0 0.7rem;
          .p1{
            margin: 0.5rem 0;
          }
        }
        .button{
          padding-left: 0.7rem;
        }
      }
      .accordion{
        padding: 0.6rem;
        box-shadow: $shadow-default;
        .accordion-title{
          border-bottom: 1px solid $grey-text;
          margin-bottom: 0.5rem;
          padding-bottom: 0.5rem;
          display: block;
        }
      }
    }
  }

  @media (max-width: 796px) {
    .content {
      .wrapper {
        grid-template-columns: 1fr;

        .divider {
          width: 95%;
          height: 1.5px;
          margin: 1rem auto;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .content {
      .content-img {
        max-height: 150px;
      }
    }
  }
}

// Tablas

.table {
  thead {
    tr {
      th {
        border: none;
        font-size: 0.875rem;
        font-weight: 400;
        color: #a4a4a4;
      }
    }
  }

  td,
  th {
    border: none;
  }

  tbody {
    tr {
      th,
      td {
        font-size: 0.813rem;
        font-weight: 400;
        color: #434343;
      }

      &:nth-child(2n + 1) {
        background: #ececec;
      }
    }
  }
}

// Tabla Mi Cuenta
.account {
  table {
    tbody {
      tr {
        &:nth-child(2n + 1) {
          background: rgba(216, 216, 216, 0.3);
        }
      }
    }
  }

  @media (max-width: 796px) {
    table {
      tbody {
        tr {
          &:nth-child(2n + 1) {
            // background: inherit;
          }
        }
      }
    }
  }
}
// Vistas Ultimos Pagos & Estados de Cuenta
.status {
  table {
    tbody {
      tr {
        &:nth-child(2n + 1) {
          background: inherit;
        }
      }
    }
  }

  @media (max-width: 796px) {
    // thead{
    // 	border: none;
    // 	clip: rect(0 0 0 0);
    // 	height: 1px;
    // 	margin: -1px;
    // 	overflow: hidden;
    // 	padding: 0;
    // 	position: absolute;
    // 	width: 1px;
    // }
    // tbody{
    // 	tr{
    // 		border-bottom: 3px solid #ddd;
    // 		display: block;
    // 		margin-bottom: 1rem;
    // 		&:nth-child(n){
    // 			background: transparent;
    // 			border-bottom: none;
    // 		}
    // 	}
    // 	td{
    // 		border-bottom: 1px solid #ddd;
    // 		border-left: none;
    // 		display: block;
    // 		font-size: .8em;
    // 		text-align: right;
    // 		padding: 1rem 0.5rem;
    // 		&::before{
    // 			content: attr(data-label);
    // 			float: left;
    // 			font-weight: 500;
    // 			color: $tc-black;
    // 		}
    // 		&:last-child{
    // 			border-bottom: none;
    // 		}
    // 	}
    // }
  }
}

table {
  border: none;

  thead {
    tr {
      td {
        color: $grey-text;
        font-weight: 300;
        font-size: 0.875rem;
        padding: 0.8rem;
      }
    }
  }

  tbody {
    tr {
      td {
        color: $tc-black;
        font-weight: 300;
        font-size: 0.75rem;
        padding: 0.625rem;

        .btn-download {
          border: none;
          background: transparent;

          img {
            width: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 796px) {
    // thead{
    // 	border: none;
    // 	clip: rect(0 0 0 0);
    // 	height: 1px;
    // 	margin: -1px;
    // 	overflow: hidden;
    // 	padding: 0;
    // 	position: absolute;
    // 	width: 1px;
    // }
    // tbody{
    // 	tr{
    // 		border-bottom: 3px solid #ddd;
    // 		display: block;
    // 		margin-bottom: 1rem;
    // 		&:nth-child(n){
    // 			background: transparent;
    // 			border-bottom: none;
    // 		}
    // 	}
    // 	td{
    // 		border-bottom: 1px solid #ddd;
    // 		border-left: none;
    // 		display: block;
    // 		font-size: .8em;
    // 		text-align: right;
    // 		padding: 1rem 0.5rem;
    // 		&::before{
    // 			content: attr(data-label);
    // 			float: left;
    // 			font-weight: 500;
    // 			color: $tc-black;
    // 		}
    // 		&:last-child{
    // 			border-bottom: none;
    // 		}
    // 	}
    // }
  }
}

.table-movements,
.movements,
.suscritos {
  @media (max-width: 796px) {
    .table-mobile {
      display: block;
      overflow-x: auto;
      width: 100%;

      table {
        width: 550px;
      }
    }
    .table-patpass{
      table{
        width: 850px;
      }
    }
    // Diseño antiguo de las tablas
    // table{
    // 	thead{
    // 		border: none;
    // 		clip: rect(0 0 0 0);
    // 		height: 1px;
    // 		margin: -1px;
    // 		overflow: hidden;
    // 		padding: 0;
    // 		position: absolute;
    // 		width: 1px;
    // 	}
    // 	tbody{
    // 		tr{
    // 			border-bottom: 3px solid #ddd;
    // 			display: block;
    // 			margin-bottom: 1rem;
    // 			&:nth-child(n){
    // 				background: transparent;
    // 				border-bottom: none;
    // 			}
    // 		}
    // 		td{
    // 			border-bottom: 1px solid #ddd;
    // 			border-left: none;
    // 			display: block;
    // 			font-size: .8em;
    // 			text-align: right;
    // 			padding: 1rem 0.5rem;
    // 			&::before{
    // 				content: attr(data-label);
    // 				float: left;
    // 				font-weight: 500;
    // 				color: $tc-black;
    // 			}
    // 			&:last-child{
    // 				border-bottom: none;
    // 			}
    // 		}
    // 	}
    // }
  }
}

// Tabla Filtros
.filtros {
  table {
    width: 100%;

    tr {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      @include align-items(flex-end);

      td {
        padding: 0.5rem;
      }

      .btn-search {
        label {
          font-size: 0;
          display: none;
        }

        .btn-action {
          text-align: center;
          margin-top: 0.3rem;

          .btn-active {
            width: 90%;
          }
        }
      }
    }
  }

  @media (max-width: 796px) {
    table {
      tr {
        grid-template-columns: 1fr;

        td {
          width: 100%;
        }

        .btn-search {
          label {
            display: none;
          }

          .btn-action {
            .btn-active {
              width: 100%;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

// Contenido
.content {
  .title {
    text-align: center;
    padding: 2rem 0;
  }

  .withdrawal {
    border: 1px solid $tc-grey;
    border-radius: 5px;
    box-shadow: $shadow-default;
    margin: 2.188rem 0;

    .stepper {
      margin: 1rem 0;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        li {
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);

          &::after {
            content: '';
            width: 3vw;
            border-top: 1px solid silver;
          }

          &:last-child {
            &::after {
              content: none;
            }
          }

          span {
            font-weight: bold;
            border-radius: 40px;
            width: 40px;
            height: 40px;
            margin: 0 0.5rem;
            color: $tc-white;
            background: rgba(0, 0, 0, 0.3);

            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
          }
        }

        .active {
          span {
            background: $tc-red;
            color: $tc-white;
          }
        }
      }
    }

    .wrapper-one {
      display: grid;
      grid-template-columns: 50% 1px 50%;
      padding: 1rem;

      &.alone {
        display: block;

        .one {
          margin: auto;
          width: 60%;

          .title-withdrawal,
          .text {
            text-align: center;
            max-width: 100%;
          }
        }
      }

      .divider {
        height: 100%;
        width: 1.5px;
        background: $grey-text;
      }

      .one,
      .two {
        margin: 0.5rem;
        padding: 1rem;
      }

      .one {
        // border-right: 2px solid $grey-text;
        .title-withdrawal {
          padding: 0;
          margin-bottom: 10px;
          text-align: left;
        }

        .text {
          font-size: 0.85rem;
          max-width: 85%;
          color: $grey-text;
        }

        .list-withdrawal {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            @include flexbox();

            margin: 1rem 0;

            span {
              font-weight: 700;
              margin-right: 0.5rem;
            }

            p {
              margin: 0;
              color: $grey-text;
              font-size: 0.85rem;
            }
          }
        }
      }

      .two {
        p {
          font-size: 0.85rem;
          color: $grey-text;

          span {
            color: $tc-red;
            font-size: 1.25rem;
          }
        }

        form {
          .form-group {
            @include flexbox();
            @include justify-content(space-between);
            @include align-items(center);
            @include flex-wrap(wrap);
            text-align: right;

            label {
              font-size: 0.75rem;
              font-weight: 500;
              margin: 0;
            }

            .form-control {
              width: 50%;
            }
          }

          .checkbox {
            display: block;

            .form-check {
              margin: 0.5rem 0;

              @include flexbox();
              @include align-items(center);
              @include justify-content(space-between);

              .form-check-label {
                font-weight: 100;
                font-size: 0.875rem;
              }
            }

            .more-info {
              // position: absolute;
              // top: 0;
              // right: 0;
              @include flexbox();
              @include align-items(center);

              a, button:not(.btn-link) {
                background-color: transparent;
                border: 0;
                font-size: 0.625rem;
                color: $tc-red;
                margin-left: 0.313rem;
              }
            }
          }

          .btn-action {
            padding: 1rem 0;

            .btn-active {
              width: 50%;
              margin: 0 auto;
              display: block;
              font-weight: bold;
            }
          }
        }
        .text-info{
          p{
            font-size: 10px;
          }
          .italic{
            font-style: italic;
            margin-top: 0.5rem;
          }
        }
      }

      @media (max-width: 992px) {
        grid-template-columns: 1fr;

        .one,
        .two {
          margin: 0.5rem 0;
        }

        .one {
          border-right: none;
          // border-bottom: 2px solid $grey-text;
        }
      }

      @media (max-width: 576px) {
        .one{
          display: none;
        }
        .one,
        .two {
          padding: 0;
        }

        .two {
          form {
            .form-group {
              @include flex-direction(column);
              align-items: inherit;
              text-align: left;

              label {
                margin-bottom: 0.5rem;
              }

              .form-control {
                width: 100%;
              }
            }

            .checkbox {
              .more-info {
                position: inherit;
                margin-left: -22px;
              }
            }

            .btn-action {
              .btn-active {
                width: 100%;
              }
            }

            .checkbox {
              .form-check {
                display: inherit;
              }
            }
          }
        }
      }
    }
  }
}

// Navbar

// Header
.header {
  padding: 1rem;
  box-shadow: $shadow-default;
  padding-bottom: 0;
  background-color: #fff;
  min-height: 4rem;

  .container-header {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);

    .logo {
      img {
        width: 180px;
      }
    }

    .user {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);

      .header-right {
        margin-bottom: 10px;

        .user-menu {
          position: relative;

          .btn-user-menu {
            background: transparent;
            border: none;
            cursor: pointer;
            padding: 0.5rem;
            border-radius: 3px;
            box-shadow: $shadow-default;

            &.dropdown-toggle::after {
              display: none;
            }

            img {
              width: 30px;
            }
          }

          .caret {
            display: none;
            left: 0;
            overflow: hidden;
            position: absolute;
            bottom: -15px;
            width: 100%;
            height: 8px;
            z-index: 2;

            &::after {
              box-shadow: $shadow-default;
              background: $tc-white;
              border-color: $tc-grey;
              border-radius: 2px 0 0 0;
              left: 50%;
              border: 1px solid $tc-grey;
              content: '';
              display: block;
              position: absolute;
              top: -0.5px;
              width: 10px;
              height: 10px;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              -webkit-transform-origin: 0% 0;
              transform-origin: 0% 0;
              z-index: 1;
            }
          }
        }

        &:hover {
          .user-menu {
            .caret {
              display: block;
            }
          }

          .hover-submenu {
            display: block;
          }
        }
      }

      .hover-submenu {
        display: none;
        width: 286px;
        position: absolute;
        height: 10px;
        right: calc(-100px - -10px);
        margin-right: 15px;
      }

      .user-menu {
        .list-submenu {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            border-left: 3px solid transparent;
            margin: 0;
            padding: 0;
            border-top: 1px solid $tc-grey;

            &:first-child {
              border-top: none;
            }

            a {
              color: $tc-black;
              background-color: $tc-white;

              @include flexbox();

              line-height: 44px;
              padding: 0 20px;
              text-decoration: none;
              white-space: nowrap;
              overflow: hidden;

              img {
                width: 20px;
                margin-right: 10px;
              }
            }

            &:hover {
              border-left: 3px solid $tc-red;
            }
          }
        }
      }

      p,
      a {
        margin: 0;
        padding-right: 1rem;
        font-size: 1.2rem;
      }

      .dropdown {
        position: relative;

        .sub-menu {
          display: none;
          position: absolute;
          box-shadow: $shadow-default;
          background: $tc-white;
          border-radius: 3px;
          left: 30%;
          transform: translate(-50%, 0);
          z-index: 3;
          width: 225px;

          .sub-item {
            margin: 0;
            list-style: none;
            padding: 0.5rem 1rem;
            border-left: 2px solid transparent;

            img {
              width: 20px;
              margin-right: 10px;
            }

            a {
              font-size: 0.85rem;
              color: $tc-black;
              text-decoration: none;
              cursor: pointer;
            }

            &:hover {
              border-left: 2px solid $tc-red;
            }
          }
        }
        // .show{
        // 	display: block;
        // }
        &:hover {
          .sub-menu {
            display: block;
          }
        }
      }

      .icon-setting,
      .icon-logout {
        padding: 0.5rem;
        border-radius: 3px;
        background: $tc-white;
        box-shadow: $shadow-default;
        border: none;

        img {
          width: 30px;
        }
      }

      .icon-logout {
        margin-left: 1rem;
        margin-bottom: 10px;
      }
    }

    .show {
      .header-right {
        .user-menu {
          .caret {
            display: block;
          }
        }
      }

      .submenu-user {
        display: block;
      }
    }
  }

  // Header Responsive
  .navbar {
    @include justify-content(center);

    width: 100%;
    margin: 0;
    padding: 0;

    .nav {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);

      position: relative;
      width: 100%;

      .logo {
        display: none;

        img {
          width: 180px;
        }
      }

      .header-right {
        display: none;

        @include align-items(center);

        .user-menu {
          position: relative;
          margin: 0 1rem;

          .btn-user-menu {
            background: transparent;
            border: none;
            cursor: pointer;

            &.dropdown-toggle::after {
              display: none;
            }

            &:focus {
              outline: none;
              box-shadow: none;
            }

            img {
              width: 30px;
            }
          }
          .caret {
            display: none;
            left: 0;
            overflow: hidden;
            position: absolute;
            bottom: -17px;
            width: 100%;
            height: 8px;
            z-index: 2;
            &::after {
              box-shadow: $shadow-default;
              background: $tc-white;
              border-color: $tc-grey;
              border-radius: 2px 0 0 0;
              left: 50%;
              border: 1px solid $tc-grey;
              content: '';
              display: block;
              position: absolute;
              top: -0.5px;
              width: 10px;
              height: 10px;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              -webkit-transform-origin: 0% 0;
              transform-origin: 0% 0;
              z-index: 1;
            }
          }
          &:hover {
            .caret,
            .hover-submenu,
            .submenu-user {
              display: block;
            }
          }
        }

        .burger-menu {
          background: transparent;
          border: none;
          cursor: pointer;

          img {
            width: 25px;
          }

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      .hover-submenu {
        display: none;
        width: 286px;
        position: absolute;
        height: 10px;
        right: m#{a}x(4px, calc(env(safe-area-inset-right) - 15px));
        margin-right: 15px;
        top: 30px;
      }

      .user-menu {
        .list-submenu {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            border-left: 3px solid transparent;
            margin: 0;
            padding: 0;
            border-top: 1px solid $tc-grey;

            &:first-child {
              border-top: none;
            }

            a {
              color: $tc-black;

              @include flexbox();

              line-height: 44px;
              padding: 0 28px;
              text-decoration: none;
              white-space: nowrap;
              overflow: hidden;

              img {
                width: 20px;
                margin-right: 10px;
              }
            }

            &:hover {
              border-left: 3px solid $tc-red;
            }
          }
        }
      }

      .nav-list {
        .list {
          margin: 0;
          padding: 0;
          list-style: none;

          @include flexbox();
          @include align-items(center);

          position: relative;

          .welcome {
            display: none;
            margin: 0 0 1rem 1.1rem;
          }

          .nav-item {
            &:hover {
              .caret,
              .sub-nav,
              .hover-submenu {
                display: block;
              }
            }
            margin-top: 1.5rem;
            position: relative;

            .nav-link {
              border-bottom: 4px solid transparent;
              padding: 0;
              margin: 0 1.1rem;
              padding-bottom: 1rem;
              color: $tc-black;
              font-weight: 400;
              font-size: 0.875rem;
            }

            .caret {
              display: none;
              left: 0;
              overflow: hidden;
              position: absolute;
              bottom: -10px;
              width: 100%;
              height: 8px;
              z-index: 3;

              &::after {
                box-shadow: $shadow-default;
                background: $tc-white;
                border-color: $tc-grey;
                border-radius: 2px 0 0 0;
                left: 50%;
                border: 1px solid $tc-grey;
                content: '';
                display: block;
                position: absolute;
                top: -0.5px;
                width: 10px;
                height: 10px;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                -webkit-transform-origin: 0% 0;
                transform-origin: 0% 0;
                z-index: 1;
              }
            }

            .hover-submenu {
              display: none;
              width: 286px;
              position: absolute;
              height: 10px;
              top: 40px;
              left: 50%;
              transform: translate(-50%, 0);
            }

            .sub-nav {
              display: none;
              position: absolute;
              box-shadow: $shadow-default;
              border: 1px solid $tc-grey;
              background: $tc-white;
              border-radius: 5px;
              width: 220px;
              left: 60%;
              transform: translate(-50%, 0);
              z-index: 2;
              top: 50px;

              li {
                border-left: 3px solid transparent;

                a {
                  line-height: 44px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  letter-spacing: -0.016em;
                  color: $tc-black;
                  text-decoration: none;
                  margin-left: 20px;
                  display: block;
                }

                &:hover {
                  border-left: 3px solid $tc-red;
                }
              }
            }
          }

          &:hover {
            .nav-link {
              &:hover {
                color: $tc-red;
              }
            }
          }

          .active {
            .nav-link {
              border-bottom: 4px solid $tc-red;
              color: $tc-red;
            }
          }
        }

        .list {
          .nav-item {
            &:hover {
              .caret,
              .sub-nav,
              .hover-submenu {
                display: block;
              }
            }
          }
        }
      }
    }

    .show-submenu {
      .header-right {
        .user-menu {
          .caret {
            display: block;
          }
        }
      }

      .submenu-user {
        display: block;
      }
    }

    @media (max-width: 992px) {
      .nav {
        @include justify-content(space-between);

        .header-right {
          display: flex;
          margin-right: 15px;
        }

        .logo {
          display: block;
        }
      }

      .nav-list {
        display: none;
      }
    }
    // @media(max-width:768px){
    // 	.nav{
    // 		.submenu-user{
    // 			width: 100%;
    // 			margin-right: 0;
    // 			.list-submenu{
    // 				padding: 0 20px;
    // 				margin: 0 auto;
    // 			}
    // 		}
    // 	}
    // }
  }

  .container-sidebar {
    display: none;

    .overlay-sidebar {
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      z-index: 99999999;
      top: 0;
      left: 0;
    }

    .sidebar-nav {
      background: $tc-white;
      position: fixed;
      top: 0;
      left: 0;
      width: 20rem;
      height: 100vh;
      z-index: 99999999;
      overflow-y: auto;
      overflow-x: hidden;

      @include flexbox();
      @include flex-direction(column);

      box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);

      .sidebar-header {
        padding: 1rem 1rem;
        box-shadow: $shadow-default;

        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);

        .welcome {
          margin: 0;
          font-size: 1rem;
        }
        .header-right {
          margin-bottom: 10px;
  
          .user-menu {
            position: relative;
            .btn-user-menu {
              background: transparent;
              border: none;
              cursor: pointer;
              padding: 0.5rem;
              border-radius: 3px;
              box-shadow: $shadow-default;
  
              &.dropdown-toggle::after {
                display: none;
              }
              
              &:focus {
                outline: none;
                box-shadow: none;
              }
  
              img {
                width: 30px;
              }
            }
  
            .caret {
              display: none;
              left: 0;
              overflow: hidden;
              position: absolute;
              bottom: -15px;
              width: 100%;
              height: 8px;
              z-index: 2;
  
              &::after {
                box-shadow: $shadow-default;
                background: $tc-white;
                border-color: $tc-grey;
                border-radius: 2px 0 0 0;
                left: 50%;
                border: 1px solid $tc-grey;
                content: '';
                display: block;
                position: absolute;
                top: -0.5px;
                width: 10px;
                height: 10px;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                -webkit-transform-origin: 0% 0;
                transform-origin: 0% 0;
                z-index: 1;
              }
            }
          }
          .hover-submenu {
            display: none;
            width: 320px;
            position: absolute;
            height: 10px;
            right: calc(-40px - -10px);
            margin-right: 15px;
          }

          .user-menu {
    
            .list-submenu {
              padding: 0;
              margin: 0;
              list-style: none;
    
              li {
                border-left: 3px solid transparent;
                margin: 0;
                padding: 0;
                border-top: 1px solid $tc-grey;
    
                &:first-child {
                  border-top: none;
                }
    
                a {
                  color: $tc-black;
    
                  @include flexbox();
    
                  line-height: 44px;
                  padding: 0 28px;
                  text-decoration: none;
                  white-space: nowrap;
                  overflow: hidden;
    
                  img {
                    width: 20px;
                    margin-right: 10px;
                  }
                }
    
                &:hover {
                  border-left: 3px solid $tc-red;
                }
              }
            }
          }
  
          &:hover {
            .user-menu {
              .caret {
                display: block;
              }
            }
  
            .hover-submenu {
              display: block;
            }
  
            .submenu-user {
              display: block;
            }
          }
        }
      }

      .sidebar-ul {
        margin-top: 1rem;
        padding: 0;
        list-style: none;
        border-bottom: 1px solid hsla(0, 0%, 60%, 0.31373);
        #accordion1{
          display: none;
        }

        .sidebar-li {
          margin-top: 0.4rem;
          @include flexbox();
          @include align-items(center);
          @include justify-content(space-between);
          &:nth-child(3){
            .dropdown-icon{
              display: none;
            }
          }

          .sidebar-li-item {
            // padding: 0.8rem 1rem;
            // border-left: 4px solid transparent;
            // color: $tc-black;
            // transition: .2s ease-in;
            // Estilos antiguos
            color: $tc-black;
            border-left: 4px solid transparent;
            padding: 1rem;

            @include flexbox();
            @include align-items(center);
            @include justify-content(space-between);

            transition: 0.3s;

            &:hover {
              border-left: 4px solid $tc-red;
              text-decoration: none;
            }
          }

          .dropdown-icon {
            margin-right: 25px;
            img {
              transform: rotate(180deg);
              transition: all 0.3s;
              width: 20px;
            }
          }
          .collapsed{
            .dropdown-icon{
              img{
                transform: rotate(0deg);
                transition: all 0.3s;
              }
            }
          }

          .active {
            border-left: 4px solid $tc-red;
          }
        }

        // .collapsed {
        //   .sidebar-li-item {
        //     .dropdown-icon {
        //       img {
        //         transform: rotate(0deg);
        //         transition: all 0.3s;
        //       }
        //     }
        //   }
        // }

        .subsidebar-li {
          .sidebar-li {
            margin-left: 20px;
          }
        }
      }

      .sidebar-footer {
        margin: 1rem 0;
        text-align: center;

        .btn-outline {
          width: 80%;
        }
      }
    }
  }

  .close-sidebar {
    display: none;

    .overlay-sidebar {
      animation: 0.3s c ease-in-out;
      opacity: 0;
      visibility: hidden;
    }

    .sidebar-nav {
      animation: 0.3s b ease-in-out;
      left: 0;
      opacity: 1;
      visibility: hidden;
    }
  }

  .show-sidebar {
    display: block;

    .overlay-sidebar {
      animation: 0.3s e ease-in-out;
    }

    .sidebar-nav {
      left: 0;
      animation: 0.3s d ease-in-out;
    }
  }

  @media (max-width: 992px) {
    padding: 1rem 0;

    .container-fluid {
      padding: 0 !important;

      .container-header {
        display: none;
      }
    }
  }
}

// Bloqueo de tarjeta

.wrapper{
  .wrapper-cardlock{
    display: grid;
    grid-template-columns: 50% 1px 50%;
    padding: 1.5rem 0;
    .one, .two{
      padding: 2rem 2rem 2rem 1rem;
    }
    .one{
      h3{
        font-weight: 400;
        font-size: 25px;
        margin: 0;
      }
      p{
        font-size: 14px;
        font-weight: 300;
        color: $tc-black;
        margin: 10px 0 0 0;
        width: 80%;
      }
      .form-control, .tricot-card{
        margin-top: 25px;
        width: 80%;
      }
    }
    .two{
      .title-switch{
        width: 90%;
        margin: 0 auto;
        h4{
          text-align: left;
          margin: 0;
          font-weight: 500;
          font-size: 18px;
        }
        .wrapper-switch{
          width: 100%;
        }
      }
      .wrapper-switch{
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        padding-bottom: 0.5rem;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 1rem;
        &:first-child{
          border-bottom: 1px solid #EDEDED;
        }
        p{
          margin: 0;
        }
      }
    }
  }
  @media (max-width: 992px){
    .wrapper-cardlock{
      grid-template-columns: 1fr;
      .one{
        text-align: center;
        p, .form-control{
          width: 100%;
        }
        .tricot-card{
          width: 70%;
        }
      }
    }
  }
  @media (max-width: 576px){
    .wrapper-cardlock{
      padding: 0;
      .one, .two{
        padding: 1rem 0;
      }
      .one{
        .tricot-card{
          width: 100%;
        }
      }
      .two{
        .title-switch{
          h4{
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
.label{
    color: red;
    font-size: 22px;
    font-weight: initial;
}

.contanerMainnn{
    display: flex;
    margin-top: 20px;
    gap: 1rem;
    flex:1
}

.containerPassword{
    display: flex;
    flex-flow: column;
    margin: 3rem;
}

.titlePassword{
    font-weight: 500;
    color: #000;
    font-size: 1.25rem;
}

.containerData{
    display: flex;
    flex-flow: column;
    padding: 1.5rem;
    border: 1px gray solid;
    justify-content: space-evenly;
    align-items: center;
    flex:1;
}

.inputText{
    border: 1px gray solid;
    padding: 5px;
    width: 250px;
}

.btnPassword{
    padding: 10px;
    color: white;
    background-color: red;
    border: 1px solid black;
    width: 200px;
    margin-top: 2rem;
}

.camposPassword{
    font-size: 15px;
    color: gray;
    font-weight: lighter;
    margin: 0.3rem;
}

p,li{
    font-size: 15px;
    color: gray;
    font-weight: lighter;
}

//Colores
$tc-red: #f30000;
$tc-white: #fff;
$tc-black: #000;
$tc-grey: #ededed;
$grey-text: #727272;
$alert-success: #ebfde6;
$alert-error: #fde6e6;
$alert-warning: #fdfbe6;
$alert-info: #deebff;

//Sombra
$shadow-default: 0 2px 10px rgba(0, 0, 0, 0.12);

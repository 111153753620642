@import './styles/utils.scss';
@import './styles/variables.scss';
@import './styles/stepOne.scss';
@import './styles/stepTwo.scss';
@import './styles/stepThree.scss';
@import './styles/stepFour.scss';
@import './styles/profile.scss';
@import './styles/patpass.scss';
@import './styles/benefits.scss';
@import './styles/payment.scss';
@import './styles/documentos.scss';
@import './styles/debt.scss';
@import './styles/insurance.scss';
@import './styles/modal.scss';
@import './styles/account.scss';
@import './styles/switch.scss';
@import './styles/navbar.scss';
@import './styles/table.scss';
@import './styles/cardlock.scss';
@import './styles/multiproduct.scss';
@import './styles/updatePassword.scss';


// Estilos Generales
html {
  font-size: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100% !important;
  padding: 0;
  margin: 0;
  font-family: 'Gotham', sans-serif;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn-disabled {
  background: $grey-text !important;
  border: none !important;
  color: $tc-white !important;
  opacity: 0.3 !important;
  cursor: auto !important;
}

button {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

select,
input {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #ced4da !important;
  }
}

#accordion {
  .toggle-filters {
    display: block;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input {
  font-size: 0.8rem !important;
  color: $grey-text !important;
}

label {
  margin-bottom: 0.3rem;
  font-size: 0.8rem;
}

select {
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  font-size: 0.8rem !important;
  color: $grey-text !important;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.box-shadow {
  border: 1px solid #ededed;
  border-radius: 5px;
  box-shadow: $shadow-default;
  margin: 2.188rem 0;
}

.wrapper {
  padding: 1rem;
}

.divider {
  height: 100%;
  width: 1.5px;
  background: $grey-text;
}

.bg-white {
  background: $tc-white;
}

.modal-content {
  position: relative;
}
// Alertas
.alert {
  min-height: 117px;
  margin: 2.188rem 0 2.813rem 0;

  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);

  color: $tc-black;

  p {
    font-size: 1.125rem;
    font-weight: 100;
    text-align: center;
    color: $tc-black;
    margin: 0.938rem 0 0 0;
  }

  .check,
  .error {
    width: 35px;
  }

  &.notification {
    @include flex-direction(row);
    min-height: 75px;
    margin: 2.188rem 0 2.813rem 0;

    p {
      font-size: 1rem;
      margin: 0 0 0 1rem;
    }

    .icon {
      width: 25px;
    }
  }
}

.alert-success {
  background: $alert-success;
  border-color: $alert-success;
}

.alert-error {
  background: $alert-error;
  border-color: $alert-error;
}

.alert-warning {
  background: $alert-warning;
  border-color: $alert-warning;
}

.alert-info {
  background: $alert-info;
  border-color: $alert-info;
}

.alert-content {
  position: relative;
}
// Titulos
.title {
  font-size: 1.25rem;
  font-weight: 400;
}
//Botones
.btn-outline {
  padding: 0.5rem 2rem;
  border: 2px solid $tc-red;
  border-radius: 30px;
  display: inline-block;
  color: $tc-red;
  background: $tc-white;
  transition: 0.2s ease-in;
  cursor: pointer;
  margin: 0;
  text-align: center;
  font-size: 0.84rem;
  font-weight: bold;

  &:hover {
    background: $tc-red;
    color: $tc-white;
    text-decoration: none;
  }
}

.btn-active {
  margin: 0;
  background: $tc-red;
  color: $tc-white;
  font-weight: bold;
}

.btn-close {
  font-size: 1.75rem;
  position: absolute;
  right: 0;
  top: 0;
}
// Paginador
.filterCats {
  padding: 1.875rem;
  display: table;
  text-align: center;
  width: auto;
  margin: 0 auto;
  li {
    display: inline-block;
    font-size: 0.84rem;
    a,
    button {
      border: 1px solid rgba(164, 164, 164, 0.2);
      font-weight: 500;
      margin: 0.6rem 0.4rem;
      padding: 0.6rem 1rem;
      transition: 0.2s ease-in;
      cursor: pointer;
      text-decoration: none;
      color: $grey-text;
      &:hover {
        background: $tc-red;
        color: $tc-white;
      }
    }
    .active {
      background: $tc-red;
      color: $tc-white;
    }
  }
  @media (max-width: 576px) {
    max-width: 100%;
    li {
      button{
        padding: 0.4rem 0.5rem;
      }
    }
  }
}
// Stepper
.stepper {
  margin: 2rem 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    li {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);

      &::after {
        content: '';
        width: 3vw;
        border-top: 1px solid silver;
      }

      &:last-child {
        &::after {
          content: none;
        }
      }

      span {
        border-radius: 40px;
        width: 40px;
        height: 40px;
        margin: 0 0.5rem;
        color: $tc-white;
        background: rgba(0, 0, 0, 0.3);

        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        font-weight: bold;
      }
    }

    .active {
      span {
        background: $tc-red;
        color: $tc-white;
      }
    }

    .pointer {
      cursor: pointer;
    }
  }
}

// // Header
// .header{
// 	padding: 1rem;
// 	box-shadow: $shadow-default;
// 	padding-bottom: 0;
// 	.container-header{
// 		@include flexbox();
// 		@include justify-content(space-between);
// 		@include align-items(center);
// 		.logo{
// 			img{
// 				width: 180px;
// 			}
// 		}
// 		.user{
// 			@include flexbox();
// 			@include justify-content(space-between);
// 			@include align-items(center);
// 			p, a{
// 				margin: 0;
// 				padding-right: 1rem;
// 				font-size: 1.2rem;
// 			}
// 			.dropdown{
// 				position: relative;
// 				.sub-menu{
// 					display: none;
// 					position: absolute;
// 					box-shadow: $shadow-default;
// 					background: $tc-white;
// 					border-radius: 3px;
// 					left: 30%;
// 					transform: translate(-50%, 0);
// 					z-index: 3;
// 					width: 225px;
// 					.sub-item{
// 						margin: 0;
// 						list-style: none;
// 						padding: 0.5rem 1rem;
// 						border-left: 2px solid transparent;
// 						img{
// 							width: 20px;
// 							margin-right: 10px;
// 						}
// 						a{
// 							font-size: 0.85rem;
// 							color: $tc-black;
// 							text-decoration: none;
// 							cursor: pointer;
// 						}
// 						&:hover{
// 							border-left: 2px solid $tc-red;
// 						}
// 					}
// 				}
// 				// .show{
// 				// 	display: block;
// 				// }
// 				&:hover{
// 					.sub-menu{
// 						display: block;
// 					}
// 				}
// 			}
// 			.icon-setting, .icon-logout{
// 				padding: 0.5rem;
// 				border-radius: 3px;
// 				background: $tc-white;
// 				box-shadow: $shadow-default;
// 				border: none;
// 				img{
// 					width: 25px;
// 				}
// 			}
// 			.icon-logout{
// 				margin-left: 1rem;
// 			}
// 		}
// 	}
// 	// Header
// 	.navbar{
// 		@include justify-content(center);
// 		width: 100%;
// 		margin: 0;
// 		padding: 0;
// 		.nav{
// 			@include flexbox();
// 			@include align-items(center);
// 			@include justify-content(center);
// 			position: relative;
// 			width: 100%;
// 			.burger-menu{
// 				display: none;
// 				background: transparent;
// 				border: none;
// 				cursor: pointer;
// 				&:focus{
// 					outline: none;
// 					box-shadow: none;
// 				}
// 			}
// 			.logo{
// 				display: none;
// 			}
// 			.nav-list{
// 				.list{
// 					margin: 0;
// 					padding: 0;
// 					list-style: none;
// 					@include flexbox();
// 					@include align-items(center);
// 					position: relative;
// 					.welcome{
// 						display: none;
// 						margin: 0 0 1rem 1.1rem;
// 					}
// 					.nav-item{
// 						padding-top: 1.5rem;
// 						position: relative;
// 						.nav-link{
// 							border-bottom: 4px solid transparent;
// 							padding: 0;
// 							margin: 0 1.1rem;
// 							padding-bottom: 1rem;
// 							color: $tc-black;
// 							font-weight: 400;
// 							font-size: 0.875rem;
// 							// &:hover{
// 							// 	color: $tc-red;
// 							// }
// 						}
// 						.sub-menu{
// 							display: none;
// 							position: absolute;
// 							border: 1px solid red;
// 							box-shadow: $shadow-default;
// 							background: $tc-white;
// 							border-radius: 3px;
// 							left: 50%;
// 							transform: translate(-50%, -50%);
// 							top: 210%;
// 							padding: 1rem 0;
// 							margin: 0 1rem;
// 							width: 150px;
// 							z-index: 3;
// 							.sub-item{
// 								border-left: 2px solid transparent;
// 								&:hover{
// 									border-left: 2px solid $tc-red;
// 								}
// 								a{
// 									font-size: 0.75rem;
// 									font-weight: 100;
// 									color: $tc-black;
// 									margin-left: 15px;
// 									text-decoration: none;
// 								}
// 							}
// 							.active{
// 								border-left: 2px solid $tc-red;
// 							}
// 						}
// 					}
// 					&:hover{
// 						.nav-link{
// 							&:hover{
// 								color: $tc-red;
// 							}
// 						}
// 						.sub-menu{
// 							display: block;
// 						}
// 					}
// 					.active{
// 						.nav-link{
// 							border-bottom: 4px solid $tc-red;
// 							color: $tc-red;
// 						}
// 					}
// 				}
// 			}
// 		}
// 		@media(max-width:992px){
// 			.nav{
// 				.burger-menu{
// 					display: block;
// 					position: absolute;
// 					top: 0;
// 					left: 0;
// 				}
// 				.logo{
// 					display: block;
// 				}
// 			}
// 			.nav-list{
// 				display: none;
// 			}
// 		}
// 	}
// 	.container-sidebar{
// 		display: none;
// 		.overlay-sidebar{
// 			width: 100vw;
// 			height: 100vh;
// 			background-color: rgba(0,0,0,.5);
// 			position: fixed;
// 			z-index: 99999999;
// 			top: 0;
// 			left: 0;
// 		}
// 		.sidebar-nav{
// 			background: $tc-white;
// 			position: fixed;
// 			top: 0;
// 			left: 0;
// 			width: 20rem;
// 			height: 100vh;
// 			z-index: 99999999;
// 			overflow-y: auto;
// 			overflow-x: hidden;
// 			@include flexbox();
// 			@include flex-direction(column);
// 			box-shadow: 0 0 6px rgba(0,0,0,.5);
// 			.sidebar-header{
// 				padding: 1.5rem 1rem;
// 				box-shadow: $shadow-default;
// 				@include flexbox();
// 				@include align-items(center);
// 				.welcome{
// 					margin: 0;
// 					font-size: 1rem;
// 				}
// 			}
// 			.sidebar-ul{
// 				margin-top: 1rem;
// 				padding: 0;
// 				list-style: none;
// 				border-bottom: 1px solid hsla(0,0%,60%,.31373);
// 				.sidebar-li{
// 					display: flex;
// 					.sidebar-li-item{
// 						padding: 0.8rem 1rem;
// 						border-left: 4px solid transparent;
// 						color: $tc-black;
// 						transition: .2s ease-in;
// 						&:hover{
// 							border-left: 4px solid $tc-red;
// 							text-decoration: none;
// 						}
// 					}
// 					.active{
// 						border-left: 4px solid $tc-red;
// 					}
// 				}
// 			}
// 			.sidebar-footer{
// 				text-align: center;
// 				.btn-outline{
// 					width: 80%;
// 				}
// 			}
// 		}
// 	}
// 	.close-sidebar{
// 		display: none;
// 		.overlay-sidebar{
// 			animation: .3s c ease-in-out;
// 			opacity: 0;
// 			visibility: hidden;
// 		}
// 		.sidebar-nav{
// 			animation: .3s b ease-in-out;
// 			left: 0;
// 			opacity: 1;
// 			visibility: hidden;
// 		}
// 	}
// 	.show-sidebar{
// 		display: block;
// 		.overlay-sidebar{
// 			animation: .3s e ease-in-out;
// 		}
// 		.sidebar-nav{
// 			left: 0;
//     	animation: .3s d ease-in-out;
// 		}
// 	}
// 	@media(max-width:992px){
// 		padding: 1rem;
// 		.container-header{
// 			display: none;
// 		}
// 	}
// }

//Footer
footer {
  background: #1f1f1f;
  margin-top: 2.5rem;

  .row {
    padding: 2rem;

    .list-footer {
      padding: 0;
      margin: 0;
      list-style: none;

      .title-footer {
        font-size: 1rem;
        font-weight: 400;
        color: $tc-white;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
      }

      li {
        a,
        p {
          color: #8c8c8c;
          font-size: 0.75rem;
          line-height: 1.8rem;
          margin: 0;
        }
      }
    }
  }

  .subfooter {
    background: rgba(0, 0, 0, 0.2);
    min-height: 80px;

    @include flexbox();
    @include align-items(center);

    font-size: 0.75rem;
    font-weight: 400;
    padding: 0 2rem;

    p {
      color: $tc-white;
    }
  }

  @media (max-width: 768px) {
    .row {
      .list-footer {
        margin: 1rem 0;
      }
    }
  }
}

// Contenido
.content {
  .title {
    text-align: center;
    padding: 2rem 0;
  }

  .withdrawal {
    border: 1px solid $tc-grey;
    border-radius: 5px;
    box-shadow: $shadow-default;

    .stepper {
      margin: 1rem 0;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        li {
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);

          &::after {
            content: '';
            width: 3vw;
            border-top: 1px solid silver;
          }

          &:last-child {
            &::after {
              content: none;
            }
          }

          span {
            border-radius: 40px;
            width: 40px;
            height: 40px;
            margin: 0 0.5rem;
            color: $tc-white;
            background: rgba(0, 0, 0, 0.3);

            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
          }
        }

        .active {
          span {
            background: $tc-red;
            color: $tc-white;
          }
        }
      }
    }

    .wrapper-three {
      display: grid;
      grid-template-columns: 100%;
      padding: 1rem;

      .one {
        border: none;

        .content-text {
          margin: 0.8rem 0;

          p {
            font-size: 1.313rem;
            color: $tc-black;
            text-align: center;
            margin: 0 auto;
            max-width: 70%;

            span {
              margin-right: 0.5rem;
              color: $tc-red;
            }
          }
        }

        form {
          max-width: 70%;
          margin: 0 auto;
          display: grid;
          grid-template-columns: 45% 45%;
          padding: 1rem;
          column-gap: 40px;

          @include justify-content(center);

          .title-date {
            grid-column: 1 / span 2;
            text-align: center;
            font-size: 1.313rem;
            color: $tc-black;
            margin: 2rem 0.5rem;
          }

          .form-group {
            @include flex-direction(column);

            align-items: inherit;

            &:first-child {
              border: none;
            }

            label {
              margin-bottom: 0.5rem;
            }

            .form-control {
              width: 100%;
            }
            
            .form-control:disabled {
              background-color: transparent;
              border: 0px solid;
            }
          }

          .btn-action {
            .btn-active,
            .btn-outline {
              width: 100%;
            }
          }
          .text-info{
            grid-column: 1 / span 2;
            margin: 1rem 0;
            p{
              font-size: 10px;
              color: $tc-black;
            }
            .italic{
              font-style: italic;
              margin-top: 0.5rem;
            }
          }
        }
      }

      .btn-mobile {
        display: none;
      }

      @media (max-width: 992px) {
        grid-template-columns: 1fr;

        .one {
          border: none;

          .content-text {
            p {
              max-width: 100%;
            }
          }

          form {
            max-width: 100%;
          }
        }
      }

      @media (max-width: 576px) {
        .one {
          padding: 0;

          form {
            padding: 0;

            @include flexbox();
            @include flex-direction(column);

            .btn-action {
              margin: 0.5rem 0;

              .btn-active,
              .btn-outline {
                margin: 0;
              }
            }
          }
        }

        .two {
          form {
            grid-template-columns: 1fr;
          }
        }

        .btn-desktop {
          display: none;
        }

        .btn-mobile {
          display: inline-block;
        }
      }
    }
  }
}

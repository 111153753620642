// Multi producto - Tarjeta prepago

.multiproduct{
  @include flexbox();
  margin-top: 2rem;
  .prepaid{
    width: 40%;
    box-shadow: $shadow-default;
    border-radius: 0.313rem;
    padding: 1rem;
    .name-card{
      margin-top: 0.313rem;
      .type-card{
        color: $tc-black;
        margin: 0;
        margin-bottom: 0.313rem;
        font-size: 0.875rem;
        font-weight: 300;
      }
      .title{
        margin: 0;
        font-weight: 500;
        color: $tc-black;
        font-size: 1.25rem;
      }
    }
    .bar-progress {
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
      margin-top: 0.9rem;
      .values {
        text-align: center;
        &:first-child{
          text-align: left;
        }
        &:last-child{
          text-align: right;
        }
        .active {
          color: $tc-red;
        }
        p {
          margin: 0;
          font-weight: 300;
          color: $grey-text;
          font-size: 0.75rem;
        }
        span {
          font-weight: 500;
          color: $tc-black;
          font-size: 1.25rem;
        }
      }
    }
    .progress {
      margin-top: 0.1rem;
      height: 0.5rem;
      .progress-bar {
        background-color: $tc-red;
        width: 20%;
      }
    }
    .direct-access{
      margin-top: 1.563rem;
      @include flexbox();
      @include justify-content(space-between);
      .box-icon{
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        text-decoration: none;
        .icon{
          width: 3.125rem;
          height: 3.125rem;
          box-shadow: $shadow-default;
          background: $tc-white;
          border-radius: 0.25rem;
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          .icon-img{
            width: 1.875rem;
          }
          .pay{
            width: 0.938rem;
          }
          .lock, .account-icon{
            width: 1.25rem;
          }
        }
        p{
          font-size: 0.75rem;
          color: $tc-black;
          font-weight: 300;
          margin: 0;
          margin-top: 0.313rem;
          text-align: center;
        }
      }
    }
    .btn-action{
      text-align: center;
      margin-top: 2.188rem;
      margin-bottom: 1rem;
      .btn-outline{
        width: 50%;
      }
    }
  }
  .credit{
    width: 60%;
    box-shadow: $shadow-default;
    border-radius: 0.313rem;
    padding: 1rem;
    .name-card{
      margin-top: 0.313rem;
      .type-card{
        color: $tc-black;
        margin: 0;
        margin-bottom: 0.313rem;
        font-size: 0.875rem;
        font-weight: 300;
      }
      .title{
        margin: 0;
        font-weight: 500;
        color: $tc-black;
        font-size: 1.25rem;
      }
    }
    .bar-progress {
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
      margin-top: 0.9rem;
      .values {
        text-align: center;
        &:first-child{
          text-align: left;
        }
        &:last-child{
          text-align: right;
        }
        .active {
          color: $tc-red;
        }
        p {
          margin: 0;
          font-weight: 300;
          color: $grey-text;
          font-size: 0.75rem;
        }
        span {
          font-weight: 500;
          color: $tc-black;
          font-size: 1.25rem;
        }
      }
    }
    .progress {
      margin-top: 0.1rem;
      height: 0.5rem;
      .progress-bar {
        background-color: $tc-red;
        width: 20%;
      }
    }
    .direct-access{
      margin-top: 1.563rem;
      @include flexbox();
      @include justify-content(space-between);
      .box-icon{
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        text-decoration: none;
        .icon{
          width: 3.125rem;
          height: 3.125rem;
          box-shadow: $shadow-default;
          background: $tc-white;
          border-radius: 0.25rem;
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          .icon-img{
            width: 1.875rem;
          }
          .pay{
            width: 0.938rem;
          }
          .lock, .account-icon{
            width: 1.25rem;
          }
        }
        p{
          font-size: 0.75rem;
          color: $tc-black;
          font-weight: 300;
          margin: 0;
          margin-top: 0.313rem;
          text-align: center;
        }
      }
    }
    .btn-action{
      text-align: center;
      margin-top: 2.188rem;
      margin-bottom: 1rem;
      .btn-outline{
        width: 50%;
      }
    }
  }
  .credit{
    margin-right: 0.625rem;
  }
  .prepaid{
    margin-left: 0.625rem;
  }
  @media (max-width: 992px){
    @include flex-direction(column);
    .credit, .prepaid{
      width: 100%;
      margin: 0;
    }
    .credit{
      margin-bottom: 1rem;
    }
  }
  @media (max-width: 576px){
    .credit, .prepaid{
      .bar-progress {
        .values {
          span {
            font-size: 0.9rem;
          }
        }
      }
      .direct-access{
        display: grid;
        grid-template-columns: 1fr 1fr;
        .box-icon{
          margin-bottom: 0.8rem;
        }
      }
      .btn-action{
        .btn-outline{
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}

// Tarjeta prepago
.prepaid-card{
  .wrapper-one{
    .one{
      .bar-progress{
        .values{
          &:first-child{
            text-align: left;
          }
          &:last-child{
            text-align: right;
            width: 100%;
          }
        }
      }
      .balance{
        font-size: 0.75rem;
        color: $grey-text;
      }
      .box-card{
        .credit-card{
          display: block;
          .type-card{
            color: $tc-black;
            font-size: 0.75rem;
            font-weight: 300;
            margin: 0;
            margin-bottom: 0.313rem;
          }
        }
        .box-switch{
          @include flexbox();
          @include flex-direction(column);
          @include align-items(flex-end);
          p{
            color: $tc-black;
            font-size: 0.75rem;
            font-weight: 300;
            margin: 0;
            margin-bottom: 0.313rem;
          }
        }
      }
    }
  }
  .wrapper-two{
    display: grid;
    grid-template-columns: 1fr;
    box-shadow: $shadow-default;
    margin-top: 1.5rem;
    .table-movements{
      margin: 1.5rem;
      overflow-x: auto;
      table{
        width: 100%;
      }
      h2{
        font-size: 1.25rem;
        color: $tc-black;
        font-weight: 500;
        margin: 0;
        margin-bottom: 1.563rem;
      }
    }
  }
  @media (max-width: 796px){
    .wrapper-one{
      .one{
        .box-card{
          @include flex-direction(inherit);
          .credit-card{
            h2{
              font-size: 1rem;
            }
          }
          .box-switch{
            p{
              text-align: right;
            }
          }
        }
      }
    }
    .wrapper-two{
      .table-movements{
        h2{
          margin-bottom: 0.625rem;
        }
      }
    }
  }
}
//Reordena tu Deuda

.debt {
  padding: 1rem;

  .title {
    color: $tc-black;
    font-size: 1.563rem;
    text-align: center;
    margin-bottom: 1.2rem;
  }
  // Seccion 1
  & > .wrapper {
    .one {
      width: 60%;
      margin: auto;

      .text {
        font-size: 0.875rem;
        color: $grey-text;
      }
    }
  }

  .wrapper-one {
    display: grid;
    grid-template-columns: 50% 1px 50%;

    .one,
    .two {
      margin: 1rem;
    }

    .one {
      .text {
        font-size: 0.875rem;
        color: $grey-text;
      }
    }

    .two {
      .list-client {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          @include flexbox();
          @include justify-content(space-between);
          @include align-items(center);

          margin: 0.5rem 0;

          &:first-child {
            margin-top: 0;
          }

          p {
            font-size: 0.875rem;
            margin: 0;
            color: $tc-black;

            strong {
              color: $tc-red;
              font-weight: 500;
            }
          }
        }
      }
    }

    .three {
      grid-column: 1 / span 3;
      margin: 1rem;

      .list-box {
        padding: 0;
        margin: 0;
        list-style: none;

        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);

        & > li, .list-box-item {
          width: 32%;
          margin: 0.3rem;
          padding: 0.5rem;
          box-shadow: $shadow-default;
          border-radius: 5px;

          .header-box {
            @include flexbox();
            @include align-items(center);
            @include justify-content(space-between);

            border-bottom: 1px solid #ddd;
            padding-bottom: 0.3rem;

            .modal{
              .modal-body{
                .margin-text{
                  .m-t{
                    margin: 0.8rem 0;
                  }
                }
              }
            }
            .modal.show{
              background: rgba(0,0,0,0.3);
            }

            p {
              margin: 0;
              font-size: 0.875rem;
            }

            .btn-detail {
              border: none;
              background: transparent;

              @include flexbox();
              @include align-items(center);

              &:focus {
                outline: none;
                box-shadow: none;
              }

              p {
                margin: 0;
              }

              .search-icon {
                width: 15px;
                margin-left: 5px;
              }
            }
          }

          .body-box {
            .list-date {
              padding: 0;
              margin: 0;
              list-style: none;

              @include flexbox();
              @include align-items(center);
              @include justify-content(space-between);

              li {
                text-align: center;
                box-shadow: none;

                p {
                  color: $grey-text;
                  font-size: 0.75rem;
                  margin-bottom: 0.5rem;

                  strong {
                    color: $tc-red;
                    font-weight: 500;
                  }

                  &:last-child {
                    color: $tc-black;
                    font-size: 0.875rem;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }

      .btn-action {
        padding: 1.5rem 0 0 0;
        text-align: center;

        .btn-active {
          width: 35%;
        }
      }
    }
  }
  // Queries Seccion 1
  @media (max-width: 992px) {
    .wrapper-one {
      grid-template-columns: 1fr;
      box-shadow: none;

      .divider {
        display: none;
      }

      .three {
        grid-column: inherit;

        .list-box {
          @include flex-direction(column);

          & > li, .list-box-item {
            width: 100%;

            .body-box {
              .list-date {
                li {
                  width: inherit;
                  margin: 0.3rem 0;
                  padding: 0.5rem 0;
                }
              }
            }
          }
        }

        .btn-action {
          .btn-active {
            width: 80%;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .wrapper {
      .one {
        width: 100%;
      }
    }
    .wrapper-one {
      .one,
      .two,
      .three {
        margin: 0;
      }

      .three {
        .list-box {
          li {
            margin: 0.5rem 0;

            &:first-child {
              margin-top: 0;
            }

            .header-box {
              .modal {
                .modal-body {
                  .list-client {
                    li {
                      p {
                        font-size: 0.865rem;
                      }
                    }
                  }

                  .btn-action {
                    .btn-active {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }

        .btn-action {
          .btn-active {
            width: 100%;
          }
        }
      }
    }
  }
  // Seccion 2
  .wrapper-two {
    display: block;
    max-width: 50%;
    margin: 0 auto;

    .title {
      padding: 1.3rem 0;
    }

    .list-client {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-top: 0.938rem;

      li {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);

        align-items: center;
        margin: 0.5rem 0;

        p {
          font-size: 0.75rem;
          margin: 0;
          color: $grey-text;

          &:last-child {
            color: $tc-black;
          }
        }
      }
    }

    .form-control {
      margin: 1rem 0;
    }

    .box-pay {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(flex-start);

      .form-check {
        label {
          font-size: 0.875rem;
          color: $tc-black;
        }
      }

      p {
        font-weight: bold;
        font-size: 1rem;
        color: $tc-red;
      }
    }

    .check-payment {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);

      .form-check {
        @include flexbox();
        @include align-items(center);

        .icon-webpay {
          width: 150px;
        }

        .icon-servipag {
          width: 120px;
        }
      }
    }

    .btn-action {
      padding: 2rem 0 0 1rem;
      text-align: center;

      .btn-active {
        width: 50%;
      }
    }
    // Queries Seccion 2
    @media (max-width: 992px) {
      max-width: 80%;
    }

    @media (max-width: 576px) {
      max-width: 100%;

      .title {
        margin: 0;
        padding: 0.8rem 0;
        font-size: 1.2rem;
      }

      .list-client {
        li {
          p {
            font-size: 0.875rem;
            margin: 0.2rem 0;
            &:last-child{
              text-align: right;
            }
          }
        }
      }

      .box-pay {
        p {
          margin: 0.3rem 0;
        }
      }

      .top {
        padding: 0.8rem 0;
      }

      .check-payment {
        .form-check {
          margin: 0.5rem 0;
          .icon-webpay, .icon-servipag{
            width: 100px;
          }
        }
      }

      .btn-action {
        padding: 2rem 0;

        .btn-active {
          width: 100%;
        }
      }
    }
  }
  // Seccion 3
  .wrapper-three {
    display: block;

    .list-client {
      padding: 0;
      margin: 0 auto;
      list-style: none;
      margin-top: 0.938rem;
      max-width: 40%;

      li {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);

        align-items: center;
        margin: 0.5rem 0;

        p {
          font-size: 0.85rem;
          margin: 0;
          color: $grey-text;

          &:last-child {
            color: $tc-black;
          }
        }
      }

      .text {
        padding-top: 1.3rem;
        color: $tc-black;
        font-size: 0.85rem;
        margin: 0.5rem 0;

        &:last-child {
          padding: 0;
        }
      }
    }

    .btn-action {
      padding: 1.3rem 0;
      text-align: center;

      .btn-active {
        width: 30%;
      }
    }

    @media (max-width: 992px) {
      .list-client {
        max-width: 100%;
      }

      .btn-action {
        .btn-active {
          width: 50%;
        }
      }
    }

    @media (max-width: 576px) {
      .btn-action {
        .btn-active {
          width: 100%;
        }
      }
    }
  }

  .box-simulate {
    display: grid;
    grid-template-columns: 50% 1px 50%;
    margin-top: 2rem;

    .one,
    .two {
      margin: 2rem;

      .title {
        color: $tc-black;
        font-weight: 300;
        font-size: 1.563rem;
      }
    }

    .one {
      p {
        color: $grey-text;
        font-size: 0.875rem;
      }

      & > ul {
        padding: 0;
        margin: 0;
        list-style: none;

        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);

        max-width: 60%;

        li {
          p {
            color: $tc-black;
            font-weight: 300;
            font-size: 0.875rem;
            margin: 0;
          }

          &:last-child {
            p {
              font-weight: 500;
              color: $tc-red;
            }
          }
        }
      }

      .form {
        display: grid;
        grid-template-columns: 48% 48%;
        grid-gap: 15px;
        margin: 1rem 0;

        .form-group {
          margin: 0;
        }

        button {
          height: 60%;
          line-height: 1;
        }
      }

      .form-group {
        display: block;
        
        &.button {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }

        label {
          font-size: 0.75rem;
          font-weight: 500;
          margin: 0;
        }

        .form-check {
          margin: 0.5rem 0;

          @include flexbox();
          @include align-items(center);
          @include justify-content(space-between);

          .form-check-label {
            font-weight: 100;
            font-size: 0.875rem;
          }

          .more-info {
            @include flexbox();
            @include align-items(center);

            a {
              font-size: 0.625rem;
              color: $tc-red;
              margin-left: 0.313rem;
            }
          }
        }
      }
    }

    .two {
      .list-client {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          @include flexbox();
          @include align-items(center);
          @include justify-content(space-between);

          margin: 0.5rem 0;

          p {
            color: $tc-black;
            font-weight: 300;
            font-size: 0.875rem;
            margin: 0;

            strong {
              color: $tc-red;
              font-weight: 500;
            }
          }
        }
      }

      .accept {
        margin-top: 0.8rem;

        label {
          font-weight: 100;
          font-size: 0.875rem;

          a {
            margin-left: 0.3rem;
            color: $tc-red;
          }
        }
        .terms{
          background: transparent;
          border: none;
          color: $tc-red;
          text-decoration: underline;
        }
      }
    }

    .three {
      grid-column: 1 / 3 span;

      @include flexbox();
      @include justify-content(center);

      margin: 1.5rem 0;

      .btn-action {
        width: 20%;
        margin: 0 0.5rem;

        .btn-active,
        .btn-outline {
          width: 95%;
        }
      }

      .btn-mobile {
        display: none;
      }
    }

    @media (max-width: 992px) {
      grid-template-columns: 1fr;

      .one,
      .two {
        margin: 0;
      }

      .three {
        grid-column: inherit;

        .btn-action {
          width: 50%;
        }
      }
    }

    @media (max-width: 576px) {
      .one, .two {
        &.mobile-hidden { 
          display: none;
        }
      }

      .one {
        ul {
          max-width: 100%;
        }

        .form {
          grid-template-columns: 1fr;

          button {
            height: unset;
            line-height: unset;
            width: 95%;
          }
        }
      }

      .three {
        @include flex-direction(column);
        margin: 0 0 1.5rem 0;

        .btn-action {
          width: 100%;
          margin: 0.5rem 0;
        }

        .btn-mobile {
          display: inline-block;
        }

        .btn-desktop {
          display: none;
        }
      }
    }
  }
}
